import WithFetchData from "../../hoc/WithFetchData";
import Payslip from "../Pay/Payslip/Payslip";
import Timesheet from "../Pay/Timesheet/TImesheet";
import LeaveOfAbsence from "../Timeoff/LeaveOfAbsence";
import Timeoff from "../Timeoff/Timeoff";
import Attendance from "../Timeoff/Attendance";
import PageNotFound from "../ErrorHandling/PageNotFound";
import MySchedule from "../Pay/MySchedule/MySchedule";

const InternalSystem = (props) => {
  console.log("InternalSystem", props);
  const componentNames = {
    payslip: Payslip,
    timecard: Timesheet,
    loa: LeaveOfAbsence,
    leaveofabsence: LeaveOfAbsence,
    timeoff: Timeoff,
    time_off: Timeoff,
    attendance: Attendance,
    default: PageNotFound,
    schedule: MySchedule
  };
  let Component =
    componentNames[props.internalSystemType] || componentNames.default;
  return <Component {...props} />;
};

export default WithFetchData(InternalSystem);
