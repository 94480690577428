import { createSlice } from "@reduxjs/toolkit";

const bookmarkDescSlice = createSlice({
  name: "Bookmark Description",
  initialState: {},
  reducers: {
    setBMDescription(state, action) {
      if (Object.keys(state).length == 0) {
        state = action.payload;
      }
      return action.payload;
    },
  },
});

export const { setBMDescription } = bookmarkDescSlice.actions;
export default bookmarkDescSlice;
