import { livePreviewInfo } from "./live-preview";

export const chooseBlock = (key, uid, entry) => {
  switch (key) {
    case "header":
      return getBlockHeader(uid, entry);
    case "footer":
      return getBlockFooter(uid, entry);
    case "languages":
      return getBlockLanguage(uid, entry, true);
    case "hero":
      return getBlockHero(uid, entry);
    case "two_column":
      return getBlockTwoColumn(uid, entry);
    case "three_column":
      return getBlockThreeColumn(uid, entry);
    case "accordion":
      return getBlockAccordionCard(uid, entry);
    case "accordion_group":
      return getBlockAccordionCard(uid, entry);
    case "fifty_fifty_card":
      return getBlockFiftyFiftyCard(uid, entry);
    case "fifty_fifty_photo":
      return getBlockFiftyFiftyPhoto(uid, entry);
    case "tiles":
      return getBlockTiles(uid, entry);
    case "bio_card":
      return getBlockTilesBioCard(uid, entry);
    case "timeline":
      return getBlockTimeline(uid, entry);
    case "carousel":
      return getBlockCarousel(uid, entry);
    case "links":
      return getBlockLinkGroupSingle(uid, entry, true);
    case "link_group":
      return getBlockLinkGroup(uid, entry);
    case "help_link":
      return getBlockHelpLink(uid, entry);
    case "callout":
      return getBlockCallout(uid, entry);
    case "api_widget":
      return getBlockApiWidget(uid, entry);
    case "content_card":
      return getBlockContentCard(uid, entry);
    case "offers_card":
      return getBlockOffersCard(uid, entry, true);
    case "offers":
    case "offers_group":
      return getBlockOffersGroup(uid, entry);
    case "promo_card":
      return getBlockPromoCard(uid, entry);
    case "promo_group":
      return getBlockPromoGroup(uid, entry);
    case "one_column":
    case "one_col":
      return getBlockOneColumn(uid, entry);
    case "quote":
      return getBlockQuote(uid, entry);
    case "weekly_updates":
      return getBlockWeeklyUpdates(uid, entry);
    case "action_panel":
      return getBlockActionPanel(uid, entry);
    case "help_topic":
      return getHelpTopic(uid, entry);
    case "link":
      return getLink(uid, entry);
    // return getBlockLinkGroup(uid, entry);
    case "quick_actions":
      return getBlockQuickActions(uid, entry);
    case "my_apps":
      return getBlockMyApp(uid, entry);
    case "offers_list":
      return getOfferList(uid, entry);
    case "link_set":
      return getBlockLinkSet(uid, entry);
    case "globals":
      return getBlockSystemErrors(uid, entry);
    case "category_group_links":
      return getCategoryGroupLinks(uid, entry);
    case "leadership_messages_list":
      return getBlockLeadershipMessagesList(uid, entry);
    case "feedbackForm":
      return getFeedbackForm(uid, entry);
    case "in_page_alert":
      console.log("in_page_alert");
      return getBlockPageAlert(uid, entry);
    case "html_widget":
      return getBlockHtmlWidget(uid, entry);

    default:
      var error = {
        message: "Component not yet created in frontend!",
      };
      return { error, uid };
  }
};

const dataError = (uid) => {
  return {
    uid,
    error: {
      message: "Data not retrieved properly on this component!",
    },
  };
};

// Assigning HERO block attributes
const getBlockHero = (uid, entry) => {
  console.log("Hero Block > ", entry);
  try {
    let data = {};
    let key = "data-cslp";
    if (entry.type === "banner" || entry.type === "sub_heading") {
      data = {
        type: entry.type,
        heading: entry.heading,
        eyebrow: entry.eyebrow_text,
        image: entry.banner_image !== null ? entry.banner_image.url : null,
      };
      if (livePreviewInfo.onLivePreview) {
        data.$ = {
          heading: entry.$?.heading[key],
          eyebrow: entry.$?.eyebrow_text[key],
          image: entry.banner_image?.$?.url[key],
        };
      }
    } else if (entry.type === "text") {
      let ctaObj = entry.cta_button != null ? entry.cta_button : entry.cta;
      data = {
        type: entry.type,
        heading: entry.heading,
        description: entry.description,
        ctaEnabled: entry.enable_cta,
        //ctaType: entry.cta_type?.type,
        cta: getCtaBlock(ctaObj),
      };
      if (livePreviewInfo.onLivePreview) {
        data.$ = {
          heading: entry.$?.heading[key],
          description: entry.description ? entry.$?.description[key] : null,
        };
      }
    }
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

// Assigning ONE COLUMN block attributes
const getBlockOneColumn = (uid, entry) => {
  try {
    //console.log("ONE COLUMN > ", entry);
    let key = "data-cslp";
    let ctaObj = entry.cta_button != null ? entry.cta_button : entry.cta;
    let data = {
      heading: entry.heading,
      description: entry.description,
      ctaEnabled: entry.enable_cta,
      cta: getCtaBlock(ctaObj),
    };
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        heading: entry.$.heading[key],
        description: entry.$.description[key],
      };
    }
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};
const getBlockMyApp = (uid, entry) => {
  //console.log(entry);
  try {
    let key = "data-cslp";
    let data = {
      link: entry.link,
      url: entry.icon?.url,
      icon: entry.icon,
      heading: entry.heading,
      halfWidth: entry.half_width,
      description: entry.description,
    };
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        icon: entry.icon?.$?.url[key],
        heading: entry.$.heading[key],
        description: entry.$.description[key],
      };
    }
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

// Assigning TWO COLUMN block attributes
const getBlockTwoColumn = (uid, entry) => {
  try {
    console.log("TWO COLUMN", entry);
    let key = "data-cslp";
    let data = {
      type: entry.type || "60-40",
      leftType: entry.left_col.type || "text",
      leftHeading: entry.left_col.heading,
      leftDescription: entry.left_col.description,
      leftImage: entry.left_col.image?.url,
      leftCtaEnabled: entry.left_col.enable_cta,
      leftCta: getCtaBlock(entry.left_col?.cta_button),
      rightType: entry.right_col.type || "text",
      rightHeading: entry.right_col.heading,
      rightDescription: entry.right_col.description,
      rightImage: entry.right_col.image?.url,
      rightCtaEnabled: entry.right_col.enable_cta,
      rightCta: getCtaBlock(entry.right_col.cta_button),
    };
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        leftHeading: entry.left_col.$.heading[key],
        leftDescription: entry.left_col.$.description[key],
        rightHeading: entry.right_col.$.heading[key],
        rightDescription: entry.right_col.$.description[key],
      };
    }
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

// Assigning THREE COLUMN block attributes
const getBlockThreeColumn = (uid, entry) => {
  try {
    //console.log("THREE COLUMN", entry);
    let key = "data-cslp";
    let data = {
      columns: [],
      $: {
        columns: [],
      },
    };
    for (let i = 0; i < entry.columns?.length; i++) {
      data.columns.push({
        icon: entry.columns[i]?.icon?.icon,
        //image: entry.columns[i]?.file?.url,
        heading: entry.columns[i]?.heading,
        items: entry.columns[i]?.items.map((item) => {
          return {
            date: item.date,
            description: item.description,
            sub_heading: item.sub_heading,
            ctaEnabled: item.enable_cta,
            cta: getCtaBlock(item.cta),
          };
        }),
      });
      if (livePreviewInfo.onLivePreview) {
        data.$.columns.push({
          icon: entry.columns[i]?.icon?.icon,
          //image: entry.columns[i]?.file?.url,
          heading: entry.columns[i]?.$.heading[key],
          items: entry.columns[i]?.items.map((item) => {
            return {
              date: item.date,
              description: item.$.description[key],
              sub_heading: item.$.sub_heading[key],
              ctaEnabled: item.enable_cta,
              cta: getCtaBlock(item.cta),
            };
          }),
        });
      }
    }
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

// Assigning ACCORDION CARD block attributes
const getBlockAccordionCard = (uid, entry) => {
  try {
    //console.log("ACCORDION CARD ");
    //console.log(entry);
    if (entry.accordions) {
      return getBlockAccordionGroup(uid, entry);
    } else {
      let key = "data-cslp";
      let data = {
        uid: entry.uid,
        contentType: entry?._content_type_uid,
        heading: entry?.heading,
        description: entry?.description,
        expand_button_text: entry?.expand_button_text,
        type: entry?.type,
        image: entry?.type === "with_image" ? entry?.image?.url : null,
        icon:entry?.type === "with_icon" ? entry?.icon_file?entry.icon_file:(entry?.icon?entry.icon.icon : null):null
      };
      if (entry.links) {
        data.children = entry.links.map((item) => {
          return { cta: getCtaBlock(item.cta) };
        });
      }
      if (livePreviewInfo.onLivePreview) {
        data.$ = {
          heading: entry.$?.heading[key],
          description: entry?.description ? entry.$?.description[key] : null,
          image: entry.image?.$?.url[key],
        };
      }
      return { data, uid };
    }
  } catch (e) {
    return dataError(uid);
  }
};

// Assigning ACCORDION GROUP block attributes
const getBlockAccordionGroup = (uid, entry) => {
  try {
    //console.log("ACCORDION GROUP>>>> ");
    //console.log(entry);
    let key = "data-cslp";
    let data = {
      contentType: entry?._content_type_uid,
      uid: entry?.uid,
      heading: entry?.heading,
      description: entry?.description,
      type: entry?.type,
      collapseAllLabel: entry?.collapse_all_label,
      expandAllLabel: entry?.expand_all_label,
      children: entry.accordions.map((item) => {
        return {
          uid: item.uid,
          heading: item.heading,
          contentType: item?._content_type_uid,
          description: item?.description,
          type: item?.type,
          image: item?.type === "with_image" ? item?.image?.url : null,
          icon:item?.type === "with_icon" ? item?.icon_file?item.icon_file:(item?.icon?item.icon.icon : null):null,
          children: item.links?.map((cta) => {
            return { cta: getCtaBlock(cta.cta) };
          }),
        };
      }),
    };
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        heading: entry.$?.heading[key],
        children: entry.accordions.map((item) => {
          return {
            heading: item.heading ? item.$?.heading[key] : null,
            description: item.description ? item.$?.description[key] : null,
            image:
              item?.type === "with_image" ? item?.image?.$?.url[key] : null,
            icon:item?.type === "with_icon" ? item?.icon_file?.$?item.icon_file[key]:(item?.icon?.$?item.icon.icon[key] : null):null,
          };
        }),
      };
    }
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

// Assigning FIFTY FIFTY CARD block attributes
const getBlockFiftyFiftyCard = (uid, entry) => {
  try {
    //console.log("FIFTY FIFTY CARD",entry);
    let key = "data-cslp";
    let ctaObj = entry.cta_button != null ? entry.cta_button : entry.cta;
    let data = {
      heading: entry.heading,
      description: entry.description,
      cardType: entry.card_type,
      halfWidth: entry.half_width,
      icon: entry.icon?.icon,
      ctaEnabled: entry.enable_cta,
      cta: getCtaBlock(ctaObj),
    };
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        heading: entry.$?.heading[key],
        description: entry.$?.description[key],
        image: entry.icon?.$?.icon?.[key],
        cta: entry.cta?.$?.label[key],
      };
    }
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

// Assigning FIFTY FIFTY PHOTO block attributes
const getBlockFiftyFiftyPhoto = (uid, entry) => {
  try {
    let data = getBlockPureFiftyFiftyPhoto(entry);
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};
const getBlockPureFiftyFiftyPhoto = (entry) => {
  //console.log(entry);
  let key = "data-cslp";
  let data = {};
  let ctaObj = entry.cta_button != null ? entry.cta_button : entry.cta;
  //if (entry.type === "leadership_message") {
  data = {
    uid: entry.uid,
    contentType: entry._content_type_uid,
    type: entry.type,
    date: entry.date,
    //shortDescription: entry.short_description,
    heading: entry.heading,
    description: entry.description,
    image: entry.image?.url,
    ctaEnabled: entry.enable_cta,
    cta: getCtaBlock(ctaObj),
  };
  if (livePreviewInfo.onLivePreview) {
    data.$ = {
      heading: entry.$?.heading[key],
      shortDescription: entry.$?.short_description[key],
      description: entry.$?.description[key],
      image: entry.image?.$?.url[key],
      cta: entry.cta?.$?.label[key],
    };
  }
  /*} else {
    data = {
      uid: entry.uid,
      contentType: entry._content_type_uid,
      type: entry.type,
      heading: entry.heading,
      description: entry.description,
      image: entry.image?.url,
      ctaEnabled: entry.enable_cta,
      cta: getCtaBlock(ctaObj),
    };
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        heading: entry.$?.heading[key],
        description: entry.$?.description[key],
        image: entry.image?.$?.url[key],
        cta: entry.cta?.$?.label[key],
      };
    }
  }*/
  return data;
};

// Assigning TILES: BIO CARD block attributes
const getBlockTilesBioCard = (uid, entry) => {
  try {
    //console.log(entry);
    let key = "data-cslp";
    let data = {
      name: entry.name,
      designation: entry.designation,
      description: entry.description,
      image: entry.image?.url,
      linkedIn: entry.linkedin_url,
      ctaEnabled: entry.enable_cta,
      cta: getCtaBlock(entry.cta),
    };
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        name: entry.name ? entry.$?.name[key] : null,
        designation: entry.designation ? entry.$?.designation[key] : null,
        description: entry.description ? entry.$?.description[key] : null,
        image: entry.image ? entry.image?.$?.url[key] : null,
        cta: entry.cta ? entry.cta?.$?.label[key] : null,
      };
    }
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

// Assigning TILES CARD block attributes
const getBlockTiles = (uid, entry) => {
  try {
    let key = "data-cslp";
    let data = {
      heading: entry.heading,
      description: entry.description,
      image: entry.image?.url,
      ctaEnabled: entry.enable_cta,
      clickableComponent: entry.clickable_component || false,
      cta: getCtaBlock(entry.cta),
    };
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        heading: entry.$?.heading[key],
        description: entry.$?.description[key],
        image: entry.image?.$?.url[key],
        cta: entry.cta?.$?.label[key],
      };
    }
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

// Assigning TIMELINE block attributes
const getBlockTimeline = (uid, entry) => {
  try {
    //console.log("TIMELINE >>", entry);
    let key = "data-cslp";
    let data = {
      heading: entry.heading,
      description: entry.description,
      icon: entry.icon?.icon,
      timelines: [],
    };
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        heading: entry.$?.heading[key],
        description: entry.$?.description[key],
        icon: entry.heading_icon?.$?.url[key],
      };
    }
    if (entry.timeline_items.length > 0) {
      for (let i = 0; i < entry.timeline_items?.length; i++) {
        data.timelines[i] = {
          heading: entry.timeline_items[i]?.heading,
          description: entry.timeline_items[i]?.description,
          date: entry.timeline_items[i]?.date,
          ctaEnabled: entry.timeline_items[i]?.enable_cta,
          cta: getCtaBlock(entry.timeline_items[i]?.cta),
        };
        if (livePreviewInfo.onLivePreview) {
          data.timelines[i].$ = {
            heading: entry.timeline_items[i].$?.heading,
            description: entry.timeline_items[i]?.$?.description,
            date: entry.timeline_items[i]?.$?.date,
            cta: getCtaBlock(entry.timeline_items[i]?.cta),
          };
        }
      }
    }
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

// Assigning CAROUSEL block attributes
const getBlockCarousel = (uid, entry) => {
  try {
    let data = {
      children: entry.carousel_items.map((item) => {
        return getBlockPureFiftyFiftyPhoto(item);
      }),
      enableCta: entry.enable_trending_topic,
    };
    if (entry.enable_trending_topic) {
      // nested object of 1, removing nest
      data['trendingTopicsGroup'] = getBlockTrendingTopic(entry.trending_topics_group[Object.keys(entry.trending_topics_group)[0]]);
    } 
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

const getBlockTrendingTopic = (entry) => {
  // let key = "data-cslp";
  // let data = {};
  // let ctaObj = entry.cta_button != null ? entry.cta_button : entry.cta;
  return {
    uid: entry.uid,
    contentType: entry._content_type_uid,
    heading: entry.heading,
    trendingTopics: entry.trending_topics_group?.map((item) => { 
      // nested object of 1, removing nest
      let trendingTopicsItem = item[Object.keys(item)[0]][Object.keys(item[Object.keys(item)[0]])[0]]
      console.log('trendingTopicsItem >>>>>>>>>>>>>>>>>>>',trendingTopicsItem);
      if(trendingTopicsItem)
      {
        return {
          uid: entry.uid,
          contentType: entry._content_type_uid,
          header: trendingTopicsItem.header,
          description: trendingTopicsItem.description,
          image: trendingTopicsItem.image?.url,
          ctaEnabled: trendingTopicsItem.enable_cta,
          cta: getCtaBlock(trendingTopicsItem.cta_button),
        };
      }
      

     }),
  };

  // console.log('zzzz', data)
  // if (livePreviewInfo.onLivePreview) {
  //   data.$ = {
  //     heading: entry.$?.heading[key],
  //     shortDescription: entry.$?.short_description[key],
  //     description: entry.$?.description[key],
  //     image: entry.image?.$?.url[key],
  //     cta: entry.cta?.$?.label[key],
  //   };
  // }

  // return data;
};

// Assigning CALLOUT block attributes
const getBlockCallout = (uid, entry) => {
  try {
    //console.log("Callout > entry", entry);
    let key = "data-cslp";
    let data = {
      align: entry.align || "left",
      heading: entry.heading,
      contentType: entry._content_type_uid,
      icon: entry.icon,
      image: entry.image?.url,
      description: entry.description,
      ctaEnabled: entry.enable_cta,
      halfWidth: entry.half_width,
      //ctaSize: entry.cta_size?.size,
      //ctaType: entry.cta_type?.type,
      cta: getCtaBlock(entry.cta_button),
    };
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        heading: entry.$?.heading[key],
        icon: entry.$?.icon[key],
        description: entry.$?.description[key],
        //cta: entry.cta?.$?.label[key],
        cta: entry.cta?.map((item) => item.$?.label[key]),
      };
    }
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

// Assigning ACTION PANEL block attributes
const getBlockActionPanel = (uid, entry) => {
  try {
    console.log("getBlockActionPanel", entry);
    let key = "data-cslp";
    let data = {
      heading: entry.heading,
      description: entry.description,
      columns: entry.columns,
      type: entry.type,
      halfWidth: entry.half_width,
      children: entry.links?.map((item) => {
        return getBlockLinkGroupSingle(uid, item);
      }),
      viewAll: entry.view_all,
      viewAllNewWindow : entry.view_all_open_new_window
    };
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        heading: entry.heading ? entry.$?.heading[key] : null,
        description: entry.description ? entry.$?.description[key] : null,
        children: entry.links?.map((item) => item.cta?.$?.label[key]),
      };
    }
    //console.log(data);
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

// Assigning LINK GROUP block attributes
const getBlockLinkGroup = (uid, entry) => {
  try {
    //console.log("getBlockLinkGroup", entry);
    let key = "data-cslp";
    let data = {
      heading: entry.heading,
      description: entry.description,
      columns: entry.columns,
      type: entry.type,
      children: entry.links?.map((item) => {
        return getBlockLinkGroupSingle(uid, item);
      }),
      viewAll: entry.view_all,
    };
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        heading: entry.heading ? entry.$?.heading[key] : null,
        description: entry.description ? entry.$?.description[key] : null,
        children: entry.links?.map((item) => item.cta?.$?.label[key]),
      };
    }
    //console.log(data);
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

const getBlockQuickActions = (uid, entry) => {
  try {
    //console.log("getBlockQuickActions", entry);
    let data = {
      uid: entry.uid,
      contentType: entry._content_type_uid,
      heading: entry.heading,
      description: entry.description,
      columns: entry.columns,
      size: entry.size,
      children: entry.links?.map((item) => {
        return { cta: getCtaBlock(item.cta || item.global_field) };
      }),
    };
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

const getBlockHelpLink = (uid, entry) => {
  try {
    //console.log("getBlockHelpLink");
    //console.log(entry);
    let key = "data-cslp";
    let data = {
      uid: entry.uid,
      contentType: entry._content_type_uid,
      heading: entry.heading,
      description: entry.description,
      children: entry.links?.map((item) => {
        return { cta: getCtaBlock(item.cta) };
      }),
    };
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        heading: entry.heading ? entry.$?.heading[key] : null,
      };
    }
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

// Assigning LINK GROUP block attributes
const getBlockLinkGroupSingle = (uid, entry, isLiveFetch = false) => {
  try {
    //console.log("getBlockLinkGroupSingle");
    //console.log(entry);
    let data = {
      uid: entry.uid,
      contentType: entry._content_type_uid,
      ctaEnabled: entry.enable_cta,
      cta: getCtaBlock(entry.cta),
    };
    //console.log(data);
    if (isLiveFetch) return { data, uid };
    else return data;
  } catch (e) {
    return dataError(uid);
  }
};

// Assigning CTA block attributes
const getCtaBlock = (entry) => {
  if (Array.isArray(entry)) {
    return entry.map((item) => getCtaBlockSingle(item));
  } else {
    return getCtaBlockSingle(entry);
  }
};

// Assigning CTA SINGLE block attributes
const getCtaBlockSingle = (entry) => {
  console.log("CTA Block", entry);
  if (!entry?.label) return null;
  return {
    type: entry.type,
    size: entry.size,
    label: entry.label,
    description: entry.description,
    buttonSize: entry.button_size || null,
    url: entry.url,
    icon: entry.icon,
    openIn: entry.open_in,
    formURL: entry.form_url,
    form_content:entry.form_content,
    nameValue:entry.nameValue,
    modal: entry.modal,
    modalContent: entry.modal_content,
    leadingIcon: entry.leading_icon,
    trailingIcon: entry.trailing_icon,
    labelHash: entry.$ ? entry.$?.label["data-cslp"] : null,
  };
};

// Assigning API WIDGET block attributes
const getBlockApiWidget = (uid, entry) => {
  try {
    //console.log("getBlockApiWidget>>> ", entry);
    let dictionaryLables = {};
    if (entry.dictionary)
      if (Array.isArray(entry.dictionary))
        for (let i = 0; i < entry.dictionary.length; i++) {
          dictionaryLables[entry.dictionary[i].name] =
            entry.dictionary[i].value || entry.dictionary[i].html_value;
          if (entry.dictionary[i].hash)
            dictionaryLables[entry.dictionary[i].name + "_hash"] =
              entry.dictionary[i].hash;
          if (entry.dictionary[i].$?.value["data-cslp"])
            dictionaryLables[entry.dictionary[i].name + "_hash"] =
              entry.dictionary[i].$?.value["data-cslp"];
        }
    let data = {
      dictionaryLables,
      dictionary: entry.dictionary || null,
      links_dictionary: entry.links_dictionary || null,
      dictionary_with_icon: entry.dictionary_with_icon?.map((item) => {
        return {
          name: item.name,
          value: item.value,
          url: item.icon?.url,
          hash: item.$?.value["data-cslp"],
        };
      }),
      links: entry.links || null,
      halfWidth: entry.half_width,
    };
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

// Assigning CONTENT CARD block attributes
const getBlockContentCard = (uid, entry) => {
  try {
    //console.log(entry);
    let key = "data-cslp";
    let ctaObj = entry.cta_button != null ? entry.cta_button : entry.cta;
    let data = {
      heading: entry.heading,
      description: entry.description,
      ctaEnabled: entry.enable_cta,
      cta: getCtaBlock(ctaObj),
    };
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        heading: entry.$?.heading[key],
        description: entry.$?.description[key],
        cta: entry.cta?.$?.label[key],
      };
    }
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

// Assigning QUOTE block attributes
const getBlockQuote = (uid, entry) => {
  try {
    //console.log("getBlockQuote", entry);
    let key = "data-cslp";
    let data = {
      author: entry.author,
      description: entry.description,
      icon: entry.icon?.icon,
    };
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        author: entry.$?.author[key],
        description: entry.$?.description[key],
        icon: entry.icon?.$?.icon,
      };
    }
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

// Assigning UPDATES block attributes
const getBlockWeeklyUpdates = (uid, entry) => {
  try {
    //console.log("getBlockWeeklyUpdates", entry);
    let key = "data-cslp";
    let data = {
      heading: entry.heading,
      halfWidth: entry.half_width,
      children: entry.reference.map((item) => {
        return {
          uid: item.uid,
          contentType: item?._content_type_uid,
          heading: item.heading,
          description: item?.description,
          ctaEnabled: item.enable_cta,
          cta: getCtaBlock(item.cta),
        };
      }),
    };
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        heading: entry.$?.heading[key],
        children: entry.reference.map((item) => {
          return {
            heading: item.heading ? item.$?.heading[key] : null,
            description: item.description ? item.$?.description[key] : null,
          };
        }),
      };
    }
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

const getBlockLinkSet = (uid, entry) => {
  try {
    //console.log("getBlockOffersCard>>", entry);
    let data = {
      align: entry.align,
      children: entry?.links?.map((item) => {
        return { cta: getCtaBlock(item.cta) };
      }),
    };
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

// Assigning OFFERS CARD block attributes
const getBlockOffersCard = (uid, entry, isLiveFetch = false) => {
  try {
    //console.log("getBlockOffersCard>>>> ", entry);
    let key = "data-cslp";
    let data = {
      contentType: entry._content_type_uid,
      redeem_link: entry.redeem_link,
      uid: entry.uid,
      heading: entry.heading,
      offerDate: entry.offer_date,
      description: entry.description,
      short_description: entry.short_description,
      image: entry.image?.url,
      ctaEnabled: entry.enable_cta,
      ctaLabel: entry.cta_label,
      limitedTimeOffer: entry.limited_time_offer,
      cta: getCtaBlock(entry.cta),
    };
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        heading: entry.$?.heading[key],
        description: entry.$?.description[key],
        cta: entry.cta?.$?.label[key],
      };
    }
    if (isLiveFetch) return { data, uid };
    else return data;
  } catch (e) {
    return dataError(uid);
  }
};

// Assigning OFFERS GROUP block attributes
const getBlockOffersGroup = (uid, entry) => {
  try {
    if (entry._content_type_uid === "offers_card")
      return getBlockOffersCard(uid, entry, true);
    //console.log("getBlockOffersGroup>>>>");
    //console.log(entry);
    let key = "data-cslp";
    let data = {
      heading: entry.heading,
      link: entry.link
        ? {
            title: entry.link?.title,
            href: entry.link?.href,
          }
        : null,
      children: entry.offers?.map((item) => {
        return getBlockOffersCard(uid, item);
      }),
    };
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        heading: entry.$?.heading[key],
        description: entry.$?.description[key],
        cta: entry.cta?.$?.label[key],
      };
    }
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

// Assigning PROMO CARD block attributes
const getBlockPromoCard = (uid, entry, isLiveFetch = false) => {
  try {
    if (entry._content_type_uid === "promo_group")
      return getBlockPromoGroup(uid, entry);
    //console.log("getBlockPromoCard>>>>");
    //console.log(entry);
    let key = "data-cslp";
    let data = {
      contentType: entry._content_type_uid,
      uid: entry.uid,
      heading: entry.heading,
      fromDate: entry.from_date,
      toDate: entry.to_date,
      description: entry.description,
      image: entry.image?.url,
      enableCta: entry.enable_cta,
      cta: getCtaBlock(entry.cta),
    };
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        heading: entry.$?.heading[key],
        description: entry.$?.description[key],
        cta: entry.cta?.$?.label[key],
      };
    }
    if (isLiveFetch) return { data, uid };
    else return data;
  } catch (e) {
    return dataError(uid);
  }
};

// Assigning OFFERS GROUP block attributes
const getBlockPromoGroup = (uid, entry) => {
  try {
    if (entry._content_type_uid === "promo_card")
      return getBlockPromoCard(uid, entry);
    //console.log("getBlockPromoGroup>>>>");
    //console.log(entry);
    let key = "data-cslp";
    let data = {
      heading: entry.heading,
      children: entry.promos?.map((item) => {
        return getBlockPromoCard(uid, item);
      }),
    };
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        heading: entry.$?.heading[key],
      };
    }
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

const getBlockLeadershipMessagesList = (uid, entry) => {
  try {
    //console.log("getBlockLeadershipMessagesList >>>> ", entry);
    let key = "data-cslp";
    let nameValue = {};
    const labels = entry.name_value;
    for (let i = 0; i < labels.length; i++) {
      nameValue[labels[i].name] = labels[i].value;
    }
    let link = {};
    const links = entry.link;
    for (let i = 0; i < links.length; i++) {
      link[links[i].title] = links[i].href;
    }
    let data = {
      nameValue,
      link,
      children: entry.reference?.map((item) => {
        return {
          uid: item.uid,
          contentType: item._content_type_uid,
          type: item.type,
          date: item.date,
          shortDescription: item.short_description,
          heading: item.heading,
          description: item.description,
          ctaLabel: item.cta_label,
          image: item.image?.url,
        };
      }),
    };
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        heading: entry.$?.heading[key],
      };
    }
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

const getHelpTopic = (uid, entry) => {
  // //console.log("uid:", uid, "entry:", entry);

  let data = entry.cta;
  return { data, uid };
};

const getLink = (uid, entry) => {
  // //console.log("uid:", uid, "entry:", entry);
  let data = entry.cta;
  return { data, uid };
};

// Assigning HEADER block attributes
const getBlockHeader = (uid, entry) => {
  //console.log("HEADER >> ", entry);
  try {
    let key = "data-cslp";
    let data = {
      apiWidget: [],
      crisisNotification: entry?.crisis_notification || [],
      website_maintenance: entry?.website_maintenance || [],
      logo: {
        altText: entry?.logo?.alt_text,
        link: entry?.logo?.link,
        location: entry?.logo?.location,
        name: entry?.logo?.name,
        logo: entry?.logo?.logo?.url,
      },
      headerIcons: entry?.header_icons?.map((item) => {
        return {
          type: item.type,
          altText: item.alt_text,
          icon: item.icon,
          name: item.name,
          url: item.url || "",
        };
      }),
    };

    let reference = entry.header_blocks[0]?.api_widget?.reference;
    if (reference?.length > 0) {
      for (let i = 0; i < reference?.length; i++) {
        let obj = {};
        for (let j = 0; j < reference[i].dictionary.length; j++) {
          obj[reference[i].dictionary[j].name] =
            reference[i].dictionary[j].value;
        }
        if (reference[i].links_dictionary) {
          for (let j = 0; j < reference[i].links_dictionary.length; j++) {
            obj[reference[i].links_dictionary[j].name] = {
              href: reference[i].links_dictionary[j].link.href,
              title: reference[i].links_dictionary[j].link.title,
              openIn: reference[i].links_dictionary[j].open_in,
              trailingIcon: reference[i].links_dictionary[j].trailing_icon,
            };
          }
        }
        data.apiWidget.push(obj);
      }
    }
    // let dictionary =
    //   entry.header_blocks[0]?.api_widget?.reference[0]?.dictionary;
    // if (dictionary?.length > 0) {
    //   for (let i = 0; i < dictionary?.length; i++) {
    //     data.apiWidget[dictionary[i].name] = dictionary[i].value;
    //   }
    // }
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        heading: entry.$?.heading[key],
      };
    }
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

// Assigning FOOTER block attributes
const getBlockFooter = (uid, entry) => {
  //console.log("FOOTER > ", entry);
  try {
    let key = "data-cslp";
    let data = {
      languageSection: getBlockLanguage(uid, entry?.language_section?.language),
      linkSectionOne: {
        heading: entry?.link_section_1?.heading,
        links: entry?.link_section_1?.links?.map((item) => {
          return { cta: getCtaBlock(item.cta) };
        }),
      },
      linkSectionTwo: {
        heading: entry?.link_section_2?.heading,
        links: entry?.link_section_2?.links?.map((item) => {
          return { cta: getCtaBlock(item.cta) };
        }),
      },
      copyrightSection: {
        copyrightText: entry?.copyright_section?.copyright_text,
        //label: entry?.copyright_section?.label + "teste",
        links: entry?.copyright_section?.links?.map((item) => {
          return { cta: getCtaBlock(item.cta) };
        }),
        logo: {
          altText: entry?.copyright_section?.logo?.alt_text,
          link: entry?.copyright_section?.logo?.link,
          location: entry?.copyright_section?.logo?.location,
          url: entry?.copyright_section?.logo?.logo?.url,
        },
      },
    };
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        heading: entry.$?.heading[key],
      };
    }
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

// Assigning LANGUAGE block attributes
const getBlockLanguage = (uid, entry, isLiveFetch = false) => {
  //console.log("getBlockLanguage >> ", entry);
  try {
    let key = "data-cslp";
    let data = {
      heading: entry?.heading,
      description: entry?.description,
      languages: entry?.languages?.map((item) => {
        let splitedItem = item.split("|");
        return { name: splitedItem[0], value: splitedItem[1] };
      }),
    };
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        heading: entry.$?.heading[key],
      };
    }
    if (isLiveFetch) return { data, uid };
    else return data;
  } catch (e) {
    return dataError(uid);
  }
};

//todo: add more data and commit it - navneetha
const getOfferList = (uid, entry) => {
  try {
    let key = "data-cslp";
    let data = {
      name_value: entry.name_value,
    };

    if (entry.offers) {
      data.offers = entry.offers.map((item) => {
        return {
          category: item.category,
          image: item.image,
          description_image: item.description_image,
          heading: item.heading,
          region: item.region,
          limited_time_offer: item.limited_time_offer,
          offer_date: item.offer_date,
          ctaEnabled: item.cta_label,
          redeem_link: item.redeem_link,
          short_description:item.short_description,
          description: item.description,
          publish_details: item.publish_details,
        };
      });
    }

    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        offers: entry.$.offers[key],
        name_value: entry.$.name_value[key],
      };
    }
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

const getBlockSystemErrors = (uid, entry) => {
  try {
    let data = {
      contentType: entry.title,
      uid: entry.uid,
      heading: entry.title,
      systemErrors: entry.system_errors,
      sessionTimeout: entry.session_time_out,
      promptMessages: entry.prompt_messages,
      toastNotifications: entry.toast_notifications,
      inPageAlerts: entry.in_page_alerts,
      crisisNotifications: entry.crisis_notifications,
      translationDisclaimer:
        entry.global_properties.length > 0
          ? entry.global_properties[0].translation_disclaimer
          : [],
      globalProperties: [],
      whiteListedDomain:
        entry.global_properties.length > 0
          ? entry.global_properties[0].white_listed_domain?.domain_names
          : [],
    };
    if (entry.global_properties && entry.global_properties[0]?.property) {
      let obj = {};
      const labels = entry.global_properties[0]?.property;
      for (let i = 0; i < labels.length; i++) {
        if (
          labels[i].name === "full_month" ||
          labels[i].name === "short_month" ||
          labels[i].name === "short_day" 
          // labels[i].name === "full_day"
        ) {
          obj[labels[i].name] = labels[i].value.split("|");
        } else if (labels[i].name === "translation_disclaimer") {
          obj[labels[i].name] = {
            header: labels[i].value,
            content: labels[i].html_value,
          };
        } else {
          obj[labels[i].name] = labels[i].value;
        }
        /*
          if (labels[i].hash) obj[labels[i].name + "_hash"] = labels[i].hash;
          if (labels[i].$?.value["data-cslp"])
            obj[array[i].name + "_hash"] = labels[i].$?.value["data-cslp"];
          */
      }
      const link_property = entry.global_properties[0]?.link_properties;
      for (let j = 0; j < link_property.length; j++) {
        obj[link_property[j].name] = {
          label: link_property[j].link.title,
          url: link_property[j].link.href,
          openIn: link_property[j].open_in,
        };
      }
      data.globalProperties = obj;
    }
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

const getFeedbackForm = (uid, entry) => {
  console.log('feedback entry', entry)
  try {
    let data = {
     heading: entry.heading,
     description: entry.description,
     formContent: entry.form_content[0].form_attributes.reference,
     nameValue:entry.nameValue
    };
    return { data, uid };
  }
  catch (e) {
    return dataError(uid);
  }
}

const getCategoryGroupLinks = (uid, entry) => {
  try {
    const data = entry.category;
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

// Assigning PAGE ALERT block attributes
const getBlockPageAlert = (uid, entry) => {
  try {
    console.log("PAGE ALERT", entry);
    let key = "data-cslp";
    let ctaObj = entry.cta_button != null ? entry.cta_button : entry.cta;
    let data = {
      uid: entry.uid,
      contentType: entry._content_type_uid,
      type: entry.type || "neutral",
      heading: entry.heading,
      description: entry.description,
      icon: entry.icon?.icon,
      ctaEnabled: entry.enable_cta,
      cta: getCtaBlock(ctaObj),
    };
    if (livePreviewInfo.onLivePreview) {
      data.$ = {
        heading: entry.$.heading[key],
        description: entry.$.description[key],
        icon: entry.icon?.$?.icon?.[key],
      };
    }
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};

const getBlockHtmlWidget = (uid, entry) => {
  try {
    let data = {
      uid: entry.uid,
      contentType: entry._content_type_uid,
      headerTitle: entry.dictionary_with_icon[0]?.value,
      headerIcon: entry.dictionary_with_icon[0]?.icon?.url,
      data: [entry.dictionary],
      link1: [entry.links_dictionary[0]],
    };
    return { data, uid };
  } catch (e) {
    return dataError(uid);
  }
};
