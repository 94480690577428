import styles from "./CarouselFiftyFiftyPhoto.module.css";
import ChooseCta from "../../hooks/ChooseCta";
import InnerHtml from "./InnerHtml";
import Modal from "../global/ui/Modal";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import "moment/locale/es";
import { useSelector } from "react-redux";

const CarouselFiftyFiftyPhoto = (props) => {
  let [showPopup, setShowPopup] = useState(false);
  let [selectedModal, setSelectedModal] = useState();

  const language = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguage
  );
  console.log("language>>>", language);

  let dateValue = `${moment(props.data?.date).format("MMM D, YYYY")}`;
  if (language) {
    moment.locale(language);
  }
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  const openModal = () => {
    let modal = props.data?.cta?.modalContent;
    if (modal != null && modal != undefined) {
      setSelectedModal({
        heading: modal.heading,
        body: modal.content,
      });
    } else {
      setSelectedModal({ heading: "", body: "" });
    }
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
  };
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
      console.log("height", height);
    }
  });

  let testingClassname = "fiftyFiftyPhoto";
  if (props.carouselTT) {
    //  && props.isMobile
    testingClassname = "fiftyFiftyPhotoTT";
  }

  let originalDescp = props.data?.description;
  console.log("originalDescp1", originalDescp);
  // function removeHtmlTags(str) {
  //   return str?.split(/<[^>]*>/).join("");
  // }
  // originalDescp = removeHtmlTags(originalDescp);

  // function convertHTML(str) {
  //   let replacements = {
  //     "&nbsp;": "",
  //     "&amp;": "&",
  //     "&lt;": "<",
  //     "&gt;": ">",
  //     "&quot;": '""',
  //     "&apos;": "'",
  //     "&lt;&gt;": "<>",
  //   };
  //   return str?.replace(
  //     /(&amp;|&lt;|&gt;|&quot;|&apos;|&lt;&gt;|&nbsp;)/gi,
  //     function (noe) {
  //       return replacements[noe];
  //     }
  //   );
  // }
  // originalDescp = convertHTML(originalDescp);
  // originalDescp = originalDescp.replace(/&nbsp;/g, " ");
  // if (removeHtmlTags(originalDescp).length > 350) {
  //   originalDescp = removeHtmlTags(originalDescp);
  //   originalDescp = `${originalDescp.substring(0, 350)}...`;
  // }

  let dateComponent;

  if (
    dateValue &&
    dateValue != "Invalid date" &&
    dateValue != "Fecha inválida"
  ) {
    dateComponent = true;
    // component = (
    //   <div className="row row-h pb-1">
    //     <div className="col-8 text-left">{dateValue}</div>
    //   </div>
    // );
  } else {
    dateComponent = false;
  }
  return (
    // <div className={`${styles[testingClassname]} `}>
    // <div className={props.carouselTT ? styles[testingClassname] : styles.fiftyFiftyPhoto}>
    <div className={`${styles.fiftyFiftyPhoto} ${styles[testingClassname]}`}>
      <div>
        <img data-cslp={props.data?.$?.image} src={props.data?.image} />
      </div>
      <div className={styles.FFcontainer}>
        {(!props.carouselTT || (props.carouselTT && props.isMobile)) && (
          <div className={`${styles.hideContainer} col-4 text-right`}>
            <div className={styles.hideHolder}>
              <button onClick={() => props.setToggle()}>
                <span className={styles.textUnderline}>
                  {globalProperties?.hide}
                </span>
                &nbsp;
                <span className={styles.eyeHolder}></span>
              </button>
            </div>
          </div>
        )}
        {/* {dateValue &&
        dateValue != "Invalid date" &&
        dateValue != "Fecha inválida" ? (
          <div className="row row-h pb-1">
            <div className="col-8 text-left">{dateValue}</div>
          </div>
        ) : (
          <></>
          // <div className="col-8 text-right "></div>
        )} */}
        {dateComponent && (
          <div className="row row-h pb-1">
            <div className="col-8 text-left">{dateValue}</div>
          </div>
        )}

        <h3 ref={ref} data-cslp={props.data?.$?.heading}>
          {props.data?.heading}
        </h3>
        <InnerHtml
          description={originalDescp}
          hash={props.data?.$?.description}
          style={styles.slideDescription}
        />
        {/* {!dateComponent && (
          <div className="row row-h pb-1">
            <div className="col-8 text-left"></div>
          </div>
        )} */}
        {/* {height < 54 && <div className={styles.divSpace}></div>} */}
        {props.data?.ctaEnabled && (
          <span className={styles.buttonHolder}>
            <ChooseCta
              data={props.data?.cta}
              size="small"
              type="secondary"
              openModal={openModal}
            />
          </span>
        )}
      </div>
      {showPopup && (
        <Modal onClose={onPopupClose} heading={selectedModal.heading}>
          <InnerHtml description={selectedModal.body} />
        </Modal>
      )}{" "}
    </div>
  );
};

export default CarouselFiftyFiftyPhoto;
