// import React from 'react';
import { useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { updateGqlData } from "../../data/gqlDataReducer";
import { GET_PROFILE_DETAILS } from "../../features/api-wrapper/apiQueries";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import APIError from "../../pages/ErrorHandling/APIError";
import useFetchData from "../../hooks/useFetchData";
import { setNotification } from "../../data/notification-slice";
import { useEffect } from "react";

function GraphQLComponent() {
  const dispatch = useDispatch();

  const { loading, error, data } = useQuery(GET_PROFILE_DETAILS, {
    client: apiMiddlewareClient,
  });

  const { data: systemError } = useFetchData("", "globals");

  dispatch(updateGqlData(data));
  useEffect(() => {
    if (systemError && systemError.data) {
      dispatch(setNotification(systemError.data));
    }
  }, [systemError]);

  if (loading) return <></>;
  if (error) return <APIError error={error} />;

  return <div></div>;
}

export default GraphQLComponent;
