import { useEffect, useState } from "react";
import ConfirmationPopup from "../global/ConfirmPrompt";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const InnerHtml = (props) => {
  let { description, hash, style, ariaLabel,trackAnchorClick } = props;
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContinue, setIsContinue] = useState(false);
  const [url, setUrl] = useState();
  const globalNotification = useSelector((state) => state.globalNotification);
  const { whiteListedDomain } = globalNotification || [];

  const onContainerClick = (e) => {
    e.preventDefault();
    let currentTag = e.target;
    let isNotFoundAnchor = true;
    while (
      currentTag.getAttribute("name") !== "inner-html-parent" &&
      isNotFoundAnchor
    ) {
      if (currentTag.tagName.toLowerCase() === "a") {
        isNotFoundAnchor = false;
        if(typeof trackAnchorClick == 'function')
        {
          trackAnchorClick(true);
          
        }
        
        
        if (currentTag.target === "_blank") {
          setUrl(currentTag.getAttribute("href"));
          let tempUrl =currentTag.getAttribute("href");
          whiteListedDomain.some((v) => {
            return tempUrl ? tempUrl.includes(v) : false;
          })
            ? window.open(tempUrl, "_blank")
            : setIsModalOpen(true);
        } else {
          navigate(currentTag.getAttribute("href"));
        }
      }
      currentTag = currentTag.parentNode;
    }
  };

  useEffect(() => {
    if (isContinue) {
      setIsModalOpen(false);
      setIsContinue(false);
      window.open(url, "_blank");
    }
  }, [isContinue]);

  return (
    <>
      <div
        name="inner-html-parent"
        onClick={(e) => onContainerClick(e)}
        data-cslp={hash}
        className={style}
        aria-label={ariaLabel}
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      ></div>
      {isModalOpen && (
        <ConfirmationPopup
          isModalOpen={isModalOpen}
          onContinue={setIsContinue}
          onClose={() => {
            setIsModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default InnerHtml;
