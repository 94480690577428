import { TextLink } from "@mgmresorts/mgm-ui";
import { useNavigate } from "react-router-dom";
import ContentAuthorNote from "../../pages/ErrorHandling/ContentAuthorNote";
import { useEffect, useState } from "react";
import ConfirmationPopup from "../global/ConfirmPrompt";
import { useSelector } from "react-redux";

const CTATextLink = (props) => {
  let size = props.size ? props.size : "small";

  if (props.data === null) return;
  if (props.data.length === 0) return;

  return (
    <>
      {props.data && (
        <>
          {Array.isArray(props.data) &&
            props.data.length !== 0 &&
            props.data.map((item, index) => (
              <CTATextLinkSingle
                data={item}
                key={index}
                size={size}
                {...props}
              />
            ))}
          {!Array.isArray(props.data) && (
            <CTATextLinkSingle size={size} {...props} />
          )}
        </>
      )}
    </>
  );
};

export default CTATextLink;

const CTATextLinkSingle = (props) => {
  const globalNotification = useSelector((state) => state.globalNotification);
  const { whiteListedDomain } = globalNotification || [];
  let url = props.data?.url;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContinue, setIsContinue] = useState(false);

  let linkVariants = ["text_link"];
  const navigate = useNavigate();

  const clickHandler = (e, opensIn) => {
    e.preventDefault();
    if (opensIn === "new_window") {
      whiteListedDomain.some((v) => url.includes(v))
        ? window.open(url, "_blank")
        : setIsModalOpen(true);
    } else if (opensIn === "same_window") {
      navigate(url);
    } else if (opensIn === "modal") {
      console.log(props.args);
      props.openModal(props.args);
    }
  };

  useEffect(() => {
    if (isContinue) {
      setIsModalOpen(false);
      setIsContinue(false);
      window.open(url, "_blank");
    }
  }, [isContinue]);

  return (
    <>
      {props.data?.label?.trim() !== "" && (
        <>
          {linkVariants.findIndex(
            (variant) => variant === props.type?.toLowerCase()
          ) !== -1 ? (
            <TextLink
              className={props.style}
              data-cslp={props.data?.labelHash}
              href={props.data.url}
              iconLeft={props.data?.leadingIcon || props.iconLeft}
              iconRight={props.data?.trailingIcon || props.iconRight}
              variant={props.size}
              inverted={props.inverted}
              target="_blank"
              onClick={(e) =>
                clickHandler(e, props.data?.openIn)
              }
            >
              {props.data.label}
            </TextLink>
          ) : (
            <ContentAuthorNote
              message={`'${props.type}' -> this varient of CTA will not be supported for this component!`}
            />
          )}
          {isModalOpen && (
            <ConfirmationPopup
              isModalOpen={isModalOpen}
              onContinue={setIsContinue}
              onClose={() => {
                setIsModalOpen(false);
              }}
            />
          )}
        </>
      )}
    </>
  );
};
