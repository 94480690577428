import styles from "./NotificationPopup.module.css";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { MY_TASKS_NOTIFICATION } from "../../features/api-wrapper/apiQueries";
import useWindowDimensions from "../../hooks/useScreenDetails";
import { useQuery } from "@apollo/client";
import CTATextLink from "../dynamic/CTATextLink";
import Loader from "../global/Loader";

const NotificationPopup = (props) => {
  // const [getNotificationData, { loading, error, data }] = useLazyQuery(GET_NOTIFICATION, {
  //   client: apiMiddlewareClient,
  // });

  const {
    loading,
    error,
    data: notifications,
  } = useQuery(MY_TASKS_NOTIFICATION, {
    client: apiMiddlewareClient,
  });

  const routeChange = () => {
    let path = props.csData[2].view_all?.href;
    if (!loading) {
      window.open(path, "_blank");
    }
  };
  const [isMobile] = useWindowDimensions();
  const currentDate = new Date();
  const oneMonthAgo = new Date(currentDate);

  oneMonthAgo.setMonth(currentDate.getMonth() - 1);

  // const formattedDate = oneMonthAgo.toISOString();

  // const currentUTCDate = new Date().toUTCString();

  // const sortedNotifications = data && data.notifications && [...data.notifications].sort((a, b) => {
  //   const timeA = new Date(a.timeReceived);
  //   const timeB = new Date(b.timeReceived);
  //   return timeB - timeA;
  // });

  // useEffect(() => {
  //   getNotificationData({
  //     variables: {
  //       fromDate: formattedDate,
  //       toDate: currentDate,

  //     },
  //   });

  // }, []);

  if (error) return " ";
  return (
    <div
      className={`${styles.container} ${
        isMobile ? styles.setMWidth : styles.setDWidth
      }`}
      style={props.style}
    >
      <div className={styles.notificationHeader}>
        <h3 className={`text-left ${styles.notificationH3}`}>
          {props.csData[2]?.my_tasks ? props.csData[2].my_tasks : ""}
        </h3>
        <CTATextLink
          size="small"
          style={`${styles.notifyLink} text-right`}
          iconLeft={props.csData[2].view_all?.leadingIcon?.icon}
          iconRight={props.csData[2].view_all?.trailingIcon?.icon}
          type="text_link"
          data={{
            label: props.csData[2]?.view_all
              ? props.csData[2].view_all.title
              : "",
            url: props.csData[2].view_all?.href,
            openIn: props.csData[2].view_all?.openIn,
          }}
        />
      </div>
      <div className={styles.scrollingContainer} onClick={routeChange}>
        {notifications && notifications.myTasks.length > 0 ? (
          notifications.myTasks.map((notify, i) => {
            // const UTCDate = new Date(notify.timeReceived);
            // const timediffMs = new Date(currentUTCDate) - UTCDate;
            // const daysDiff = Math.floor(timediffMs / (1000 * 60 * 60 * 24));
            // const hoursDifference = Math.floor(
            //   (timediffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            // );
            // const hoursMinutes = Math.floor(
            //   (timediffMs % (1000 * 60 * 60)) / (1000 * 60)
            // );
            // const seconds = Math.floor((timediffMs % (1000 * 60)) / 1000);
            const due = notify.dueOn;
            const todaySDate = new Date(new Date().toISOString().slice(0, 10));
            const currentDiff = new Date(due) - todaySDate;
            const dayMilliSeconds = 1000 * 60 * 60 * 24;
            const totalDays = currentDiff / dayMilliSeconds;
            const duedate = Math.ceil(totalDays);
            return (
              <div key={i} className={styles.notificationItem}>
                <h4>{notify.taskMessage}</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: notify.taskDescription,
                  }}
                  className={styles.notifyDescription}
                />
                {duedate < 0 && (
                  <div className={styles.taskdate_overdue}>
                    {props.csData[2]?.overdue}
                  </div>
                )}
                {duedate > 0 && duedate <= 3 && (
                  <div className={styles.taskdate_overdue}>
                    {`${props.csData[2]?.due_in} ${duedate} ${
                      duedate == 1 ? props.csData[2]?.day : props.csData[2]?.days
                    }`}
                  </div>
                )}
                {duedate > 3 && (
                  <div className={styles.taskdate_default}>
                    {`${props.csData[2]?.due_in} ${duedate} ${props.csData[2]?.days}`}
                  </div>
                )}
                {/* {daysDiff > 0 ? (
                  daysDiff == 1 ? (
                    <span>
                      {daysDiff}{" "}
                      {props.csData[2]?.day_ago ? props.csData[2].day_ago : ""}{" "}
                    </span>
                  ) : (
                    <span>
                      {daysDiff}{" "}
                      {props.csData[2]?.days_ago
                        ? props.csData[2].days_ago
                        : ""}{" "}
                    </span>
                  )
                ) : hoursDifference > 0 ? (
                  hoursDifference == 1 ? (
                    <span>
                      {hoursDifference}{" "}
                      {props.csData[2]?.hour_ago
                        ? props.csData[2].hour_ago
                        : ""}
                    </span>
                  ) : (
                    <span>
                      {hoursDifference}{" "}
                      {props.csData[2]?.hours_ago
                        ? props.csData[2].hours_ago
                        : ""}
                    </span>
                  )
                ) : hoursMinutes > 0 ? (
                  hoursMinutes == 1 ? (
                    <span>
                      {hoursMinutes}{" "}
                      {props.csData[2]?.minute_ago
                        ? props.csData[2].minute_ago
                        : ""}
                    </span>
                  ) : (
                    <span>
                      {hoursMinutes}{" "}
                      {props.csData[2]?.minutes_ago
                        ? props.csData[2].minutes_ago
                        : ""}
                    </span>
                  )
                ) : seconds == 1 ? (
                  <span>
                    {seconds}{" "}
                    {props.csData[2]?.second_ago
                      ? props.csData[2].second_ago
                      : ""}
                  </span>
                ) : (
                  <span>
                    {seconds}{" "}
                    {props.csData[2]?.seconds_ago
                      ? props.csData[2].seconds_ago
                      : ""}
                  </span>
                )} */}
              </div>
            );
          })
        ) : loading ? (
          <Loader />
        ) : (
          <p className={styles.noNotification}>
            {props.csData[2]?.no_notifications
              ? props.csData[2].no_notifications
              : ""}
          </p>
        )}
      </div>
    </div>
  );
};

export default NotificationPopup;
