
import { useState,useEffect } from "react";
import ReactDOM from "react-dom";
import WithFetchFormData from "../../../hoc/WithFetchFormData";
import styles from "./FormModal.module.css";
import closeIcon from "../../../assets/images/symbol-x-black.svg";
import ConfirmationPopup from "../../global/ConfirmPrompt";
import GetForm from "../../../hooks/GetForm";


const Backdrop = () => {
  return <div className={styles.backdrop} />;
};

const ModalOverlay = (props) => {

  const showCloseBtn =
    props.showCloseBtn != undefined ? props.showCloseBtn : true;

  return (
    <div className={styles.modal}>
      <div>
        <div className={styles.modalHeader}>
          <h3>{props.heading}</h3>
          {showCloseBtn && (
            <button className={styles.closeButton} onClick={props.onClose}>
              <img src={closeIcon} />
            </button>
          )}
        </div>
        <div className={styles.content}>{props.children}</div>
      </div>
    </div>
  );
};

const portalElement = document.getElementById("overlays");

const FormModal = (props) => {

  console.log("FormModal >> ", props.data[0].data.formLayout);
  const[fieldEdited,setFieldEdited]=useState(false);
  const [isContinue, setIsContinue] = useState(false);
  const handleFieldEdited=(childData)=>{
  setFieldEdited(childData);
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {

    if (isContinue) {
      // props.setModal(false);
      setIsModalOpen(false);
      setIsContinue(false);
      props.onClose(true);
      document.body.style.overflow = "visible";
      // window.open(props.data?.link, "_blank");
    }
  }, [isContinue]);
  const handleClose = () => {
    if (fieldEdited) {

      console.log("entered modal window");
      setIsModalOpen(true);
      document.body.style.overflow = "visible";
    }
    else {
      // setZindex(false);
      props.onClose(true)
      // props.setModal(false);
      document.body.style.overflow = "visible";
    }
  }

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          onClose={()=>handleClose()}
          heading={props.heading}
          showCloseBtn={props.showCloseBtn}
        >
          <GetForm handleFieldEdited={handleFieldEdited} {...props} />
          {props.children}
        </ModalOverlay>,
        portalElement
      )}
      {isModalOpen && (
        <ConfirmationPopup
          isModalOpen={isModalOpen}
          onContinue={setIsContinue}

          fieldEdited={fieldEdited}
          onClose={() => {
            setIsModalOpen(false);
            // setZindex(false);
          }}
        />
      )}

    </>
  );
};

export default WithFetchFormData(FormModal);
