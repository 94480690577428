import { useSelector } from "react-redux";
import styles from "./Greetings.module.css";

const Greetings = (props) => {
  const { dictionary } = props.data || [];
  const gqlData = useSelector((state) => state.gqlData);
  const currHour = new Date().getHours();
  const welcomeMsg =
    currHour < 12
      ? dictionary[0].value
      : currHour < 18
      ? dictionary[1].value
      : dictionary[2].value;
  return (
    <div className={styles.messageBox}>
      <div>
        <p>{welcomeMsg}</p>
        <h2>
          {gqlData.personalInformation
            ? `${gqlData.personalInformation.preferredFirstName}`
            : ""}
        </h2>
      </div>
    </div>
  );
};

export default Greetings;