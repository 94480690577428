// import { Button } from '@mgmresorts/mgm-ui';
import style from './tabs.module.css';
// import { Button } from "@mgmresorts/mgm-ui";

// this allows us to get the previous or next
// sibling for our current target and shift focus
const handleKeyboardNavigation = event => {
  if (event.key !== 'ArrowRight' && event.key !== 'ArrowLeft') {
    return;
  }

  const elementToFocus =
    event.key === 'ArrowRight'
      ? event.target?.nextSibling
      : event.target?.previousSibling;

  if (!elementToFocus) {
    return;
  }

  // focus on the other button
  elementToFocus.focus();
  // simulate a click to switch between tabs
  elementToFocus.click();
};

// This component should be used along the TabPanel component,
// it uses the same pattern for `id` and `aria-controls`
const Tabs = ({ onClick, selectedType, ariaLabel, tabs, type = "type1", ...rest }) => {

  return (
    <div className={style.base} role="tablist" aria-label={ariaLabel} {...rest}>
      <div className={`${type == 'type1' ? style.tabs : style.tabsType2}`}>
        {tabs.map(tab => {
          const isSelected = selectedType === tab.id;

          return (
            <button
              key={tab.id}
              className={`${type == 'type1' ? style.tab : style.tabType2} 
              ${isSelected && (type == 'type1' ? style.selectedTab : style.selectedTabType2)}`}
              onClick={() => onClick(tab.id)}
              onKeyUp={handleKeyboardNavigation}
              role="tab"
              aria-selected={isSelected}
              id={`tab-${tab.id}`}
              aria-controls={`tabpanel-${tab.id}`}
              tabIndex={isSelected ? 0 : -1}
            >
              {tab.label}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default Tabs;
