import { useState } from "react";
import ChooseCta from "../../hooks/ChooseCta";
import Modal from "../global/ui/Modal";
import InnerHtml from "./InnerHtml";
import styles from "./QuickActions.module.css";

const QuickActions = (props) => {
  //console.log("QuickActions >", props.data);
  const { children: quickActionTabs, heading } = props.data || {};
  let columnCount = Number(props.data?.columns);
  let panelSize = props.data.size ? props.data.size : "small";
  columnCount = isNaN(columnCount) ? 3 : columnCount;
  //let quickActionTabs = [...quickActionTabss, ...quickActionTabss, ...quickActionTabss, ...quickActionTabss]
  //columnCount = 2;

  let [showPopup, setShowPopup] = useState(false);
  let [selectedModal, setSelectedModal] = useState();

  const openModal = (args) => {
    let modal = props.data.children[[args]]?.cta?.modalContent;
    if (modal != null && modal != undefined) {
      setSelectedModal({
        heading: modal.heading,
        body: modal.content,
      });
    } else {
      setSelectedModal({ heading: "", body: "" });
    }
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
  };
  console.log('quickActionTabs ######',quickActionTabs);
  console.log('columnCount ######',columnCount);
  console.log('panelSize ######',panelSize);
  
  return (
    <>
      <div className={styles.quickActionsHolder}>
        {heading && <h3 className={styles.actionheader}>{heading}</h3>}
        <div className={styles.actionSection}>
          {quickActionTabs.map((val, index) => {
            return (
              <ChooseCta
                key={index}
                style={`${styles.actionTab} ${columnCount === 2 ? styles.twoCol : ""} ${columnCount === 3 ? styles.threeCol : ""} ${columnCount === 4 ? styles.fourCol : ""}`}
                data={val.cta}
                strictType="quick_actions"
                panelSize={panelSize}
                args={index}
                openModal={openModal}
              />
            );
          })}
        </div>
        {showPopup && (
          <Modal onClose={onPopupClose} heading={selectedModal.heading}>
            <InnerHtml description={selectedModal.body} />
          </Modal>
        )}
      </div>
    </>
  );
};

export default QuickActions;
