import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useEffect } from "react";
import { appInsights } from "../../appInsights";
import ContentNotFound from "./ContentNotFound";
import NetworkError from "./NetworkError";
import PageNotFound from "./PageNotFound";
import UnauthroizedAccess from "./UnauthorizedAccess";
import { setLocalStoreValue } from "../../utils/localStore";
import PageUnderDevelopment from "./PageUnderDevelopment";

const ErrorHanding = (props) => {
  useEffect(() => {
    appInsights.trackException(
      {
        error: new Error(JSON.stringify(props.error)),
        severityLevel: SeverityLevel.Error,
      },
      [props.error]
    );
    if (props.error?.status == 401) {
      setLocalStoreValue("cpath", location.pathname);
      setLocalStoreValue("token", "");
      setLocalStoreValue("apigee_token", "");
      setLocalStoreValue("MGMisAuthenticated", "false");

      window.location.replace(`${process.env.REACT_APP_BASE_URL_LOGIN}login`);
      /*if (!isElectron()) {
        window.location.replace(`${process.env.REACT_APP_BASE_URL}login`);
      }*/
    }
  }, [props.error]);

  if (props.error?.title == "Page under development")
    return <PageUnderDevelopment />;
  if (props.error?.title == "Network Error") return <NetworkError />;
  if (props.error?.status == 404) return <PageNotFound />;
  if (props.error?.status == 403) {
    return <UnauthroizedAccess />;
  }
  if (props.error?.status != 401) {
    return <ContentNotFound error={props.error} />;
  }
};

export default ErrorHanding;
