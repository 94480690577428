//import Payslip from "../pages/Pay/Payslip/Payslip";
//import Dashboard from "../pages/Dashboard/Dashboard";
//import Timesheet from "../pages/Pay/Timesheet/TImesheet";
//import LeaveOfAbsence from "../pages/Timeoff/LeaveOfAbsence";
//import Timeoff from "../pages/Timeoff/Timeoff";
//import Attendance from "../pages/Timeoff/Attendance";
//import LeadershipMessages from "../pages/News/LeadershipMessages/LeadershipMessages";
import PageNotFound from "../pages/ErrorHandling/PageNotFound";
import LayoutAllInOne from "../pages/Layouts/LayoutAllInOne";
//import MgmNewsMain from "../pages/News/MgmNews/MgmNewsMain";
import InternalSystem from "../pages/Layouts/InternalSystem";

const GetComponent = (props) => {
  const componentNames = {
    // dashboard: Dashboard,
    // payslip: Payslip,
    //timecard: Timesheet,
    //leaveofabsence: LeaveOfAbsence,
    //timeoff: Timeoff,
    //attendance: Attendance,
    //leadershipmessages: LeadershipMessages,
    layout: LayoutAllInOne,
    internalsystem: InternalSystem,
    //mgmnews: MgmNewsMain,
    default: PageNotFound,
  };

  let splitArray = props.name.split("/");
  let name = splitArray[splitArray.length - 1].split("-").join("");
  let Component;

  /*
  if ((name === "leadershipmessages" && props.pageType !== "page_leadership_messages") ) {
    name = "layout";
  }
  */

  console.log("props.pageType> ", props.pageType);
  console.log("props.name> ", props.name);

  if (props.pageType === "page_internal_systems") {
    name = "internalsystem";
  }

  if (
    props.pageType === "page_landing" ||
    props.pageType === "page_detail" ||
    props.pageType === "page_updates_and_events" ||
    props.pageType === "page_dashboard" ||
    props.pageType === "page_article"
  ) {
    Component =
      componentNames[name] === undefined
        ? componentNames.layout
        : componentNames[name];
  } else {
    Component =
      componentNames[name] === undefined
        ? componentNames.default
        : componentNames[name];
  }
  return (
    <Component
      url={props.name}
      pageType={props.pageType}
      entryId={props.entryId}
    />
  );
};

export default GetComponent;
