// import { useState } from "react";
// import FeedbackForm from "./FeedbackForm";
import { useSelector } from "react-redux";
import styles from "./PilotFeedback.module.css";
// import CTAButton from "../dynamic/CTAButton";
import ExternalButton from "../dynamic/ExternalButton";

const PilotFeedback = () => {
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );
  //approximate calculation
  const isLengthyLabel =
    globalProperties?.feedback_form_button &&
    globalProperties?.feedback_form_button?.label.length > 23;
  // const [showPopup, setShowPopup] = useState(false);

  // const openModal = (e) => {
  //   // setModalContent(args);
  //   console.log("e", e.target.value);
  //   setShowPopup(!showPopup);
  // };

  return (
    <>
      <div
        className={`${
          isLengthyLabel
            ? styles.feedbackBtnContainerH
            : styles.feedbackBtnContainer
        }`}
      >
        {globalProperties && (
          <ExternalButton
            style={styles.feedbackBtn}
            data={globalProperties?.feedback_form_button}
          />
        )}
      </div>
      {/* {showPopup && (
        <div className={styles.feedbackFormContainer}>
          <FeedbackForm />{" "}
        </div>
      )} */}
    </>
  );
};
export default PilotFeedback;
