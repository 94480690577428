import styles from "./LocationSelection.module.css";
import { useState } from "react";
import { CardContainer, Tab } from "@mgmresorts/mgm-ui";
import useWindowDimensions from "../../../hooks/useScreenDetails";
import LocationWise from "./LocationWise";

import { useQuery } from "@apollo/client";
import { apiMiddlewareClient } from "../../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_PAY_DETAILS } from "../../../features/api-wrapper/apiQueries";
import Loader from "../../../components/global/Loader";
import APIError from "../../ErrorHandling/APIError";
import ChooseCta from "../../../hooks/ChooseCta";
import { useSelector } from "react-redux";

const LocationSelection = (props) => {
  let [hideMyPay, setHideMyPay] = useState(true);
  let [selectedLocationTab, setSelectedLocationTab] = useState(0);
  const [isMobile] = useWindowDimensions();

  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );
  const handleLocationTab = (index) => {
    setSelectedLocationTab(index);
  };

  const handleHideMyPay = (bool) => {
    setHideMyPay(bool);
  };

  const locationChange = (e) => {
    let index = packedData.findIndex(
      (item) => item[0].locationToShow == e.target.value
    );
    setSelectedLocationTab(index);
  };

  const { loading, error, data } = useQuery(GET_PAY_DETAILS, {
    client: apiMiddlewareClient,
    fetchPolicy: "cache-first",
  });
  if (loading) return <Loader />;
  if (error) return <APIError error={error} />;
  if (data.payslip?.length === 0) return <p>{globalProperties?.something_went_wrong}</p>;

  const getDateFromString = (str) => {
    let dateString = str
      .split("-")
      .filter((item) => !item.includes(":"))
      .join("-");
    return new Date(dateString);
  };

  const getDaysCount = (date) => {
    //const today = new Date(2023,6,30);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const diffTime = Math.abs(date - today);
    const daysCount = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return daysCount;
  };

  const setPayPeriods = () => {
    if (packedData[selectedLocationTab].length === 1) {
      packedData[selectedLocationTab][0].period = "current";
    } else {
      if (
        packedData[selectedLocationTab][0].daysCount >
        packedData[selectedLocationTab][1].daysCount
      ) {
        packedData[selectedLocationTab][0].period = "previous";
        packedData[selectedLocationTab][1].period = "current";
      } else {
        packedData[selectedLocationTab][0].period = "current";
        packedData[selectedLocationTab][1].period = "previous";
      }
    }
  };

  const getPackedData = (apiResponse) => {
    let packingData = [];
    let locationList = [];
    for (let i = 0; i < apiResponse.length; i++) {
      let index = locationList.findIndex(
        (item) => item === apiResponse[i].location
      );
      if (index === -1) locationList.push(apiResponse[i].location);

      let locationToShow = apiResponse[i].location.split(",")[0];
      const paymentDate = getDateFromString(apiResponse[i].payslipPaymentDate);
      const endDate = getDateFromString(apiResponse[i].payslipEndDate);
      const daysCount = getDaysCount(endDate);

      let payInfo = {
        location: apiResponse[i].location,
        locationToShow,
        daysCount,
        details: {
          paymentDate,
          grossPay: apiResponse[i].earnings.reduce(
            (total, item) => total + Number(item.amount),
            0
          ),
          takeHome: apiResponse[i].takeHomePay,
          startDate: getDateFromString(apiResponse[i].payslipStartDate),
          endDate: getDateFromString(apiResponse[i].payslipEndDate),
          currencyCode: apiResponse[i].currencyCode,
          timesheetHours: apiResponse[i].timesheetHours.toFixed(2),
          gross: apiResponse[i].earnings,
          deductions: apiResponse[i].deductions,
          taxes: apiResponse[i].deductions.filter(
            (item) => item.category === "Tax_HardCoded"
          ),
          retirement: apiResponse[i].deductions.filter(
            (item) => item.category === "Retirement_HardCoded"
          ),
          others: apiResponse[i].deductions.filter(
            (item) =>
              item.category !== "Tax_HardCoded" &&
              item.category !== "Retirement_HardCoded"
          ),
          takeHomePay: [
            {
              category: "takeHomePay",
              name: "Take Home Pay",
              amount: apiResponse[i].takeHomePay,
            },
          ],
        },
      };
      packingData.push(payInfo);
    }

    let locationWiseData = [];
    for (let i = 0; i < locationList.length; i++) {
      locationWiseData.push(
        packingData.filter((item) => item.location === locationList[i])
      );
    }
    return locationWiseData;
  };

  const packedData = getPackedData(data.payslip);
  setPayPeriods();

  return (
    <div>
      {/*packedData.length > 1 && (*/}
      {props.isMultiplePropertyEmployee && (
        <div>
          {!isMobile && (
            <div className={styles.tabLocationHolder}>
              {packedData.map((item, index) => (
                <Tab
                  key={index}
                  label={item[0].locationToShow}
                  className={`${styles.tabLocationItem} ${
                    selectedLocationTab === index ? styles.active : ""
                  }`}
                  onClick={() => handleLocationTab(index)}
                />
              ))}
            </div>
          )}
          {isMobile && (
            <div className={styles.locationDropdown}>
              <select onChange={locationChange}>
                {packedData.map((item, index) => (
                  <option key={index}>{item[0].locationToShow}</option>
                ))}
              </select>
            </div>
          )}
        </div>
      )}
      <CardContainer
        className={styles.cardContainer}
        style={{ borderRadius: "8px", margin: "0", padding: "0" }}
      >
        <div className={styles.cardContainerInner}>
          <LocationWise
            apiData={packedData[selectedLocationTab]}
            hideMyPay={hideMyPay}
            handleHideMyPay={handleHideMyPay}
            hidelabel={props.tabs[0].hide_my_pay}
            hash={props.tabs[0].hide_my_pay_hash}
            tabs={props.tabs}
            externalLinks={props.externalLinks}
            chartItems={props.chartItems}
          />
          {/* <div className={styles.hideMyPay}>
            <ShowHidePay
              hideMyPay={hideMyPay}
              handleHideMyPay={handleHideMyPay}
              hash={props.tabs[0].hide_my_pay_hash}
              label={props.tabs[0].hide_my_pay}
            />
          </div> */}
          <div className={styles.linkSection}>
            {/* props.externalLinks.map((item, index) => (
              <ChooseCta
                key={index}
                type="text_link"
                data={{
                  label: item.link?.title,
                  url: item.link?.href,
                  openIn: "new_window",
                  leadingIcon: "arrow-new-window",
                  trailingIcon: null,
                  labelHash: item?.$?.title["data-cslp"],
                }}
                size="large"
              />
              )) */}
            {/*
            <ChooseCta
              type="text_link"
              data={{
                label: props.externalLinks.view_all_payslips?.title,
                url: props.externalLinks.view_all_payslips?.href,
                openIn: "new_window",
                leadingIcon: "arrow-new-window",
                trailingIcon: null,
              }}
              size="large"
            />*/}
            <ChooseCta
              type="text_link"
              data={{
                label: props.externalLinks.view_tax_documents?.title,
                url: props.externalLinks.view_tax_documents?.href,
                openIn: "new_window",
                leadingIcon: "arrow-new-window",
                trailingIcon: null,
              }}
              size="large"
            />
          </div>
        </div>
      </CardContainer>
    </div>
  );
};

export default LocationSelection;
