import { Link } from "react-router-dom";
import InnerHtml from "../../components/dynamic/InnerHtml";
import service from "./ActionPanel.module.css";
import CTATextLink from "./CTATextLink";
import { useState } from "react";
import Modal from "../global/ui/Modal";

const ActionPanel = (props) => {
  const {
    heading,
    description,
    children: service_list,
    viewAll,
    viewAllNewWindow,
  } = props.data;
  console.log("actionpanel", props, viewAllNewWindow);

  let [showPopup, setShowPopup] = useState(false);
  let [selectedModal, setSelectedModal] = useState();

  const openModal = (args) => {
    let modal = service_list[[args]]?.cta?.modalContent;
    if (modal != null && modal != undefined) {
      setSelectedModal({
        heading: modal.heading,
        body: modal.content,
      });
    } else {
      setSelectedModal({ heading: "", body: "" });
    }
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
  };

  return (
    <>
      <div className={`${service.sscontainer}`}>
        <div className={`${service.ssheadersection} col-12`}>
          <span className={service.ssheader}>{heading}</span>
          {!viewAllNewWindow ? (
            <Link className={service.ssviewall} to={viewAll.href}>
              {viewAll.title}
            </Link>
          ) : <Link className={service.ssviewall} to={viewAll.href} target="_blank">
          {viewAll.title}
        </Link>}
        </div>
        <InnerHtml
          description={description}
          style={`${service.sssubheader} col-12`}
        />
        <div className={service.ssDetailsSection}>
          {service_list.map((val, index) => {
            return (
              <div key={val.uid} className={`${service.service_border}`}>
                <CTATextLink
                  size="small"
                  iconRight="arrow-right"
                  type="text_link"
                  style={`${service.ssentry} pt-3 pb-3`}
                  args={index}
                  openModal={openModal}
                  data={{
                    label: val.cta?.label,
                    url: val.cta?.url,
                    type: "secondary",
                    openIn: val.cta?.openIn,
                    leadingIcon: val.cts?.leadingIcon,
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      {showPopup && (
        <Modal onClose={onPopupClose} heading={selectedModal.heading}>
          <InnerHtml description={selectedModal.body} />
        </Modal>
      )}
    </>
  );
};

export default ActionPanel;
