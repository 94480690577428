import { useEffect, useState } from "react";
import service from "./CategoryGroupLinks.module.css";
import TextLinkDescription from "./TextLinkDescription";
import Tabs from "../common/tabs/tabs";
import TabPanel from "../common/tab-panel/TabPanel";
import Modal from "../global/ui/Modal";
import InnerHtml from "./InnerHtml";
import { useSelector } from "react-redux";

const CategoryGroupLinks = (props) => {
  const [tabList, setTabList] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [selectedModal, setSelectedModal] = useState();
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  useEffect(() => {
    if (props.data && props.data.length > 0) {
      const tabs = props.data.map((tabs) => {
        return { id: tabs.category_name, label: tabs.category_name };
      });
      tabs.splice(0, 0, { id: globalProperties.all, label: globalProperties.all });
      setTabList(tabs);
      setSelectedType(tabs[0].label);
    }
  }, [props.data, globalProperties.all]);

  const openModal = (args, index) => {
    let modal = props.data[index]?.category[args].cta?.modalContent;
    if (modal != null && modal != undefined) {
      setSelectedModal({
        heading: modal.heading,
        body: modal.content,
      });
    } else {
      setSelectedModal({ heading: "", body: "" });
    }
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
  };

  const categoryList = (category, index) => {
    return (
      <div className={`${service.sscontainer}`} key={index}>
        <div className={`${service.categoryHeaderSection} col-12`}>
          <h2 className={service.categoryHeader}>{category.category_name}</h2>
        </div>
        <div className={service.ssDetailsSection}>
          {category.links.map((val, ind) => {
            return (
              <div key={val.uid} className={`${service.service_border}`}>
                <TextLinkDescription
                  size="small"
                  style={`${service.categoryEntry}`}
                  args={ind}
                  openModal={(args) => {
                    openModal(args, index);
                  }}
                  data={{
                    ...val.global_field,
                    openIn: val.global_field?.open_in,
                    leadingIcon: { icon: val.global_field?.leading_icon },
                    trailingIcon: {
                      icon: val.global_field?.trailing_icon,
                      size: "small",
                      color: "#00012b",
                      variant: "outlined",
                    },
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`${service.mobilenav} col-7`}>
        {tabList && tabList.length > 0 && (
          <select onChange={(event) => setSelectedType(event.target.value)}>
            {tabList.map((tab) => {
              return (
                <option value={tab.label} key={tab.label}>
                  {tab.label}
                </option>
              );
            })}
          </select>
        )}
      </div>
      <Tabs
        onClick={setSelectedType}
        selectedType={selectedType}
        tabs={tabList}
        className={service.categoryTabStyle}
      />

      {props.data &&
        props.data.length > 0 &&
        props.data.map((category, index) => {
          return (
            <>
              {selectedType !== tabList[0]?.label ? (
                <TabPanel
                  key={category.uid}
                  selectedType={selectedType}
                  id={category.category_name}
                >
                  {categoryList(category, index)}
                </TabPanel>
              ) : (
                categoryList(category, index)
              )}
            </>
          );
        })}
      {showPopup && (
        <Modal onClose={onPopupClose} heading={selectedModal.heading}>
          <InnerHtml description={selectedModal.body} />
        </Modal>
      )}
    </>
  );
};

export default CategoryGroupLinks;
