import { createSlice } from "@reduxjs/toolkit";

const gqlDataSlice = createSlice({
  name: "gqlData",
  initialState: {},
  reducers: {
    updateGqlData: (state, action) => {
      console.log("GQL Data", action.payload);
      if (Object.keys(state).length == 0) {
        state = action.payload;
      }
      return state;
    },
    setHidePayslip: (state, action) => {
      state.profile.hidePayslip = action.payload;
    },
    setLicencePlate: (state, action) => {
      if (action.payload !== undefined && action.payload !== null) {
        state.personalInformation.licencePlate = action.payload;
      }
    },
    setPersonalInformation: (state, action) => {
      if (action.payload !== undefined && action.payload !== null) {
        let updatedPersonalInfo = action.payload;
        for (let key in updatedPersonalInfo) {
          if (key != "licencePlate") {
            state.personalInformation[key] = updatedPersonalInfo[key];
          }
        }
      }
    },
    setFavourites: (state, action) => {
      if (action.payload !== undefined && action.payload !== null) {
        state.profile.favourites = action.payload;
      }
    },
    setPreferredLanguage: (state, action) => {
      if (action.payload !== undefined && action.payload !== null) {
        state.profile.preferredLanguage = action.payload;
      }
    },
    setPreferredLanguageTemp: (state, action) => {
      if (action.payload !== undefined && action.payload !== null) {
        state.profile.preferredLanguageTemp = action.payload;
      }
    },
    setLangChange: (state, action) => {
      if (action.payload !== undefined && action.payload !== null) {
        state.hasLangChanged = action.payload;
      }
    },
  },
});

export const {
  updateGqlData,
  setHidePayslip,
  setLicencePlate,
  setPersonalInformation,
  setFavourites,
  setPreferredLanguage,
  setPreferredLanguageTemp,
  setLangChange,
} = gqlDataSlice.actions;

export default gqlDataSlice.reducer;
