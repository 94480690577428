import HeroBanner from "../components/dynamic/HeroBanner";
import HeroText from "../components/common/HeroText";
import HeroSubHeading from "../components/common/HeroSubHeading";
import TwoColumn from "../components/dynamic/TwoColumn";
import FiftyFiftyPhoto from "../components/dynamic/FiftyFiftyPhoto";
import FiftyFiftyCard from "../components/dynamic/FiftyFiftyCard";
import Accordion from "../components/dynamic/Accordion";
import AccordionGroup from "../components/dynamic/AccordionGroup";
import Tile from "../components/dynamic/Tile";
import CarouselBlock from "../components/dynamic/CarouselBlock";
import Bio from "../components/dynamic/Bio";
import ThreeColumn from "../components/dynamic/ThreeColumn";
import OffersCard from "../components/dynamic/OffersCard";
import PromoGroup from "../components/dynamic/PromoGroup";
import OneColumn from "../components/dynamic/OneColumn";
import Timeline from "../components/dynamic/Timeline";
import MyApp from "../components/dynamic/MyApp";
import Callout from "../components/common/Callout";
import ContentCardPlain from "../components/common/ContentCardPlain";
import QuickActions from "../components/dynamic/QuickActions";
import OfferCardList from "../components/dynamic/OfferCardList";
import ActionPanel from "../components/dynamic/ActionPanel";
import LinkSet from "../components/dynamic/LinkSet";
import Quote from "../components/dynamic/Quote";
import WeeklyUpdates from "../pages/News/UpdateEvents/WeeklyUpdates";
import UpcomingEvents from "../pages/News/UpdateEvents/UpcomingEvents";
import Features from "../pages/News/UpdateEvents/Features";
import Occupancy from "../pages/News/UpdateEvents/Occupancy";
import LeadershipMessagesList from "../components/dynamic/LeadershipMessagesList";
import CalloutHorizontal from "../components/common/CalloutHorizontal";
import MgmNews from "../components/dynamic/MgmNews";
import Greetings from "../pages/Dashboard/Greetings";
import MyTasks from "../pages/Dashboard/MyTasks";
import MyTimesheet from "../pages/Dashboard/MyTimesheet";
import MgmNewsDashboard from "../pages/Dashboard/MgmNews";
import Mgmoffer from "../components/common/mgmoffer/Mgmoffer";
import ContentAuthorNote from "../pages/ErrorHandling/ContentAuthorNote";
import UpdatesandEvents from "../pages/Dashboard/UpdatesandEvents";
import Cases from "../pages/Dashboard/Cases";
import CategoryGroupLinks from "../components/dynamic/CategoryGroupLinks";
import TrendingTopcisGroup from "../components/dynamic/TrendingTopicsGroup";
import PageAlert from "../components/dynamic/PageAlert";
import MyScheduleBoard from "../pages/Dashboard/MyScheduleBoard";

const GetDynamicComponent = (props) => {
  //console.log("GetDynamicComponent > ", props);
  const componentMapping = [
    { name: "hero", component: "hero" },
    { name: "2_col", component: "twocolumn" },
    { name: "two_column", component: "twocolumn" },
    { name: "three_column", component: "threecolumn" },
    { name: "accordion_card", component: "accordion" },
    { name: "accordion", component: "accordion" },
    { name: "accordiongroup", component: "accordiongroup" },
    { name: "50_50_card", component: "fiftyfiftycard" },
    { name: "fifty_fifty_card", component: "fiftyfiftycard" },
    { name: "50_50_Photo", component: "fiftyfiftyphoto" },
    { name: "fifty_fifty_photo", component: "fiftyfiftyphoto" },
    { name: "tiles", component: "tiles" },
    { name: "bio_card", component: "bio" },
    { name: "carousel", component: "carousel" },
    { name: "trending_topics_group", component: "TrendingTopcisGroup" },
    { name: "offers_card", component: "offerscard" },
    { name: "promo_card", component: "PromoGroup" },
    { name: "one_column", component: "OneColumn" },
    { name: "one_col", component: "OneColumn" },
    { name: "timeline", component: "Timeline" },
    { name: "my_apps", component: "MyApp" },
    { name: "callout", component: "Callout" },
    { name: "content_card", component: "ContentCardPlain" },
    { name: "quick_actions", component: "QuickActions" },
    { name: "offers_list", component: "OfferCardList" },
    { name: "action_panel", component: "ActionPanel" },
    { name: "link_set", component: "LinkSet" },
    { name: "quote", component: "Quote" },
    { name: "weekly_updates", component: "WeeklyUpdates" },
    { name: "upcoming_events", component: "UpcomingEvents" },
    { name: "featured_news", component: "Features" },
    { name: "property_occupancy", component: "Occupancy" },
    { name: "leadership_messages_list", component: "LeadershipMessagesList" },
    { name: "callout_horizontal", component: "CalloutHorizontal" },
    { name: "mgm_news", component: "MgmNews" },
    { name: "greetings", component: "Greetings" },
    { name: "time_sheet", component: "MyTimesheet" },
    { name: "offers", component: "Mgmoffer" },
    { name: "dashboard_news", component: "MgmNewsDashboard" },
    { name: "property_updates", component: "UpdatesandEvents" },
    { name: "hr_it_cases", component: "Cases" },
    { name: "my_tasks", component: "MyTasks" },
    { name: "category_group_links", component: "CategoryGroupLinks" },
    { name: "in_page_alert", component: "PageAlert" },
    { name: "dashboard_my_schedule", component: "MyScheduleBoard"}
  ];
  
  const componentNames = {
    herobanner: HeroBanner,
    herotext: HeroText,
    herosubheading: HeroSubHeading,
    twocolumn: TwoColumn,
    threecolumn: ThreeColumn,
    accordion: Accordion,
    accordiongroup: AccordionGroup,
    fiftyfiftyphoto: FiftyFiftyPhoto,
    fiftyfiftycard: FiftyFiftyCard,
    tiles: Tile,
    bio: Bio,
    carousel: CarouselBlock,
    TrendingTopcisGroup,
    offerscard: OffersCard,
    PromoGroup,
    OneColumn,
    Timeline,
    MyApp,
    Callout,
    ContentCardPlain,
    QuickActions,
    OfferCardList,
    ActionPanel,
    LinkSet,
    Quote,
    WeeklyUpdates,
    UpcomingEvents,
    Features,
    Occupancy,
    LeadershipMessagesList,
    CalloutHorizontal,
    MgmNews,
    Greetings,
    MyTimesheet,
    Mgmoffer,
    MgmNewsDashboard,
    UpdatesandEvents,
    Cases,
    MyTasks,
    CategoryGroupLinks,
    PageAlert,
    MyScheduleBoard,
  };

  if (props.error)
    return (
      <ContentAuthorNote
        message={`'${props.comp}' -> ${props.error?.message}`}
      />
    );

  let index = componentMapping.findIndex((item) => {
    if (props.comp === "api_widget") return item.name === props.type;
    else return item.name === props.comp;
  });

  if (index === -1)
    return (
      <ContentAuthorNote
        message={`'${props.comp}' -> Component not yet created in frontend!`}
      />
    );

  let componentName = componentMapping[index]?.component;

  if (componentName === "MyApp" && props.comp !== "api_widget") return <></>;

  if (componentName === "hero") {
    if (props.data?.type === "banner") {
      componentName = "herobanner";
    } else if (props.data?.type === "text") {
      componentName = "herotext";
    } else if (props.data?.type === "sub_heading") {
      componentName = "herosubheading";
    } else {
      return (
        <ContentAuthorNote
          message={`'${props.comp}' -> The type not specified on Contentstack!`}
        />
      );
    }
  }
  if (componentName === "accordion") {
    if (props.data?.contentType === "accordion") {
      componentName = "accordion";
    } else if (props.data?.contentType === "accordion_group") {
      componentName = "accordiongroup";
    } else {
      return (
        <ContentAuthorNote
          message={`'${props.comp}' -> The type not specified on Contentstack!`}
        />
      );
    }
  }
  if (componentName === "Callout") {
    if (props.data?.contentType === "callout") {
      componentName = "Callout";
    } else if (props.data?.contentType === "callout_horizontal") {
      componentName = "CalloutHorizontal";
    } else {
      return (
        <ContentAuthorNote
          message={`'${props.comp}' -> The type not specified on Contentstack!`}
        />
      );
    }
  }
  if (!componentName)
    return (
      <ContentAuthorNote
        message={`Something went wrong! Component not derived properly!`}
      />
    );

  let Component = componentNames[componentName];

  if (!Component)
    return (
      <ContentAuthorNote
        message={`Something went wrong! Component not derived properly!`}
      />
    );
  return <Component data={props.data} />;
};

export default GetDynamicComponent;
