import { Icon, Snackbar } from "@mgmresorts/mgm-ui";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import {
  GET_USER_ENTERED_CONTEST,
} from "../../features/api-wrapper/apiQueries";
import Loader from "../../components/global/Loader";
import useFetchData from "../../hooks/useFetchData";
import ErrorHanding from "../../pages/ErrorHandling/ErrorHanding";
import EditProfile from "./EditProfile";
import "../../styles/profile.css";
import styles from "./PersonalInfo.module.css";

import { useSelector } from "react-redux";
import LanguageSelection from "./LanguageSelection";
import ProfileLaunchContest from "./ProfileLaunchContest";

const PersonalInfo = () => {
  const gqlData = useSelector((state) => state.gqlData);
  const [showModal, setShowModal] = useState(false);
  const [snackbar, setSnackbar] = useState(false);

  console.log("gqldata",gqlData)

  let phoneNum =
    gqlData.personalInformation && gqlData.personalInformation.homePhone
      ? gqlData.personalInformation.homePhone.replace("+1", "")
      : "";

  let r = /(\D+)/g,
    npa = "",
    nxx = "",
    last4 = "";
  phoneNum = phoneNum ? phoneNum.replace(r, "") : "";
  npa = phoneNum ? phoneNum.substr(0, 3) : "";
  nxx = phoneNum ? phoneNum.substr(3, 3) : "";
  last4 = phoneNum ? phoneNum.substr(6, 4) : "";
  phoneNum = phoneNum ? "(" + npa + ")" + " " + nxx + "-" + last4 : "";

  const handleSnackbar = (childData) => {
    setSnackbar(childData);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSnackbar(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [snackbar]);


  const { loading:loading1, error:error1, data:data1 } = useQuery(GET_USER_ENTERED_CONTEST, {
    client: apiMiddlewareClient,
  });
  const { loading, error, data } = useFetchData("/profile", "page_profile");
  
  if (loading || loading1) return <Loader />;
  if (error) return <ErrorHanding error={error} />;
  if (error1) return <ErrorHanding error={error1} />;

  const userLocation = gqlData?.profile?.userLocation;

  const toasterMessage =
    data[2].data.dictionaryLables?.profile_have_been_submitted;
  let languageSection = data.filter((item) => item.key == "languages");

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    window.scrollTo(0, 0);
  };

  return (
    <div className={`${styles.personalInfoContainer} col-12`}>
      {snackbar ? (
        <Snackbar
          className="snackbar-box-profile"
          open
          message={toasterMessage}
          variant="two-line"
        />
      ) : (
        ""
      )}

      <button className="personal_info_text" onClick={openModal}>
        {data[2].data.dictionaryLables?.personal_info_title}
        <span className="edit_text">
          {data[2].data.links ? data[2].data?.links[0]?.title : ""}{" "}
          <Icon
            className="pencil_icon"
            color="blue"
            name="pencil"
            size="small"
            variant="outlined"
          />
        </span>
      </button>
      {showModal && (
        <EditProfile
          setModal={closeModal}
          profData={data}
          apiData={gqlData}
          phoneNum={phoneNum}
          handleSnackbar={handleSnackbar}
          workEmail={
            gqlData.personalInformation &&
            gqlData.personalInformation.workEmailAddresses
          }
        />
      )}
      <div className={`${styles.sections} ${styles.personalsubtitle}`}>
        {data[2].data.dictionaryLables?.personal_info_description}.
      </div>
      <div>
        <div>
          <div>
            <div className={styles.sections}>
              <div className={styles.field1}>
                {data[2].data.dictionaryLables?.full_name}
              </div>

              <div className={styles.field2}>
                {gqlData.personalInformation
                  ? gqlData.personalInformation.fullName
                  : ""}
              </div>
            </div>
            <div className={styles.sections}>
              <div className={styles.field1}>
                {data[2].data.dictionaryLables?.preferred_name}
              </div>
              <div className={styles.field2}>
                {gqlData.personalInformation
                  ? gqlData.personalInformation.preferredFirstName
                  : ""}
              </div>
            </div>
            <div className={styles.sections}>
              <div className={styles.field1}>
                {/* {data[2].data.dictionaryLables?.preferred_name} */}
                {data[2]?.data?.dictionaryLables?.preferred_last_name}
              </div>
              <div className={styles.field2}>
                {gqlData.personalInformation
                  ? gqlData.personalInformation.preferredLastName
                  : ""}
              </div>
            </div>
            <div className={styles.sections}>
              <div className={styles.field1}>
                {data[2].data.dictionaryLables?.personal_email}
              </div>
              <div className={styles.field2}>
                {gqlData.personalInformation
                  ? gqlData.personalInformation.personalEmail
                  : ""}
              </div>
            </div>
            <div className={styles.sections}>
              <div className={styles.field1}>
                {data[2].data.dictionaryLables?.personal_secondary_email}
              </div>
              <div className={styles.field2}>
                {gqlData.personalInformation
                  ? gqlData.personalInformation.secondaryEmail
                  : ""}
              </div>
            </div>

            <div className={styles.sections}>
              <div className={styles.field1}>
                {data[2].data.dictionaryLables?.mobile_phone}
              </div>
              <div className={styles.field2}>{phoneNum}</div>
            </div>
            <div className={styles.sections2}>
              <div className={styles.field1}>
                {data[2].data.dictionaryLables?.mailing_address}
              </div>
              <div className={styles.field2}>
                {gqlData.personalInformation
                  ? gqlData.personalInformation.addressLine1
                  : ""}{" "}
                {gqlData.personalInformation
                  ? gqlData.personalInformation.addressLine2
                  : ""}
              </div>
            </div>
            <div className={styles.sections}>
              <div className={styles.field1}></div>
              <div className={styles.field2}>
                {gqlData.personalInformation
                  ? gqlData.personalInformation.city
                  : ""}
                {", "}
                {gqlData.personalInformation
                  ? gqlData.personalInformation.state
                  : ""}{" "}
                {gqlData.personalInformation
                  ? gqlData.personalInformation.postalCode
                  : ""}
              </div>
            </div>
            {userLocation != "" && (userLocation?.toLowerCase() == "las vegas, nv"||userLocation?.toLowerCase() == "vegas corporate")
            ? (
              <>
            <div className={styles.parkingH}>
              <div className={styles.parking}>
                {data[2].data.dictionaryLables?.parking}
              </div>
              <div className={styles.parkingsection} dangerouslySetInnerHTML={{
                  __html: data[2].data.dictionaryLables?.parking_description,
                }}>
                {/* {data[2].data.dictionaryLables?.parking_description} */}
              </div>
            </div>
            <div className={styles.sections}>
              <div className={styles.field1}>
                {data[2].data.dictionaryLables?.license_plate}
              </div>
              <div>
                {gqlData.personalInformation &&
                  gqlData.personalInformation.licencePlate.length > 0 && (
                    <div>
                      {gqlData.personalInformation.licencePlate.map(
                        (data, index) => {
                          return (
                            <div key={index}>
                              {data.state} {data.plateNo.toUpperCase().trim()}
                            </div>
                          );
                        }
                      )}{" "}
                    </div>
                  )}
              </div>
            </div>
            </>): ""}

            <LanguageSelection
              languageSection={languageSection[0]?.data}
              source="profile"
            />
          </div>
        </div>
      </div>
      {data.map((item, index) => {
        if (item.key === "html_widget" && data1?.hasUserEnteredContest?.userContestParticipatedStatus === false) {
          return <ProfileLaunchContest key={index} data={item.data} userEnteredContest={data1?.hasUserEnteredContest?.userContestParticipatedStatus} />;
        }
      })}
    </div>
  );
};

export default PersonalInfo;
