import pendingimg from "../../assets/images/timer-stop-watch.svg";
import availableimg from "../../assets/images/checkmark-circle.svg";

import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
//import { GET_TIMEOFFREQUEST_DETAILS } from "../../features/api-wrapper/apiQueries";
import { GET_TIMEOFFREQUEST_DETAILS_UPDATED } from "../../features/api-wrapper/apiQueries";
import { useQuery } from "@apollo/client";
import moment from "moment";
import APIError from "../ErrorHandling/APIError";
import Loader from "../../components/global/Loader";
import { useEffect, useState } from "react";


const TimeoffRequest = (props) => {
  const [timeOffReqstData, setTimeOffReqstData] = useState();
  const { loading, error, data } = useQuery(
    GET_TIMEOFFREQUEST_DETAILS_UPDATED,
    {
      client: apiMiddlewareClient,
      fetchPolicy: "no-cache",
    }
  );

  //const hashKey = "data-cslp";
  const time_righttitle =
    props?.attdata?.data?.dictionaryLables?.time_off_requests;
  const time_righttitle_hash =
    props?.attdata?.data?.dictionaryLables?.time_off_requests_hash;

  const submittedOn = props?.attdata?.data?.dictionaryLables?.approved_on;
  const submittedOn_hash =
    props?.attdata?.data?.dictionaryLables?.approved_on_hash;

  const time_approved = props?.attdata?.data?.dictionaryLables?.approved;
  const time_approved_hash =
    props?.attdata?.data?.dictionaryLables?.approved_hash;

  const time_nopending =
    props?.attdata?.data?.dictionaryLables?.no_pending_time_off_requests;
  const time_nopending_hash =
    props?.attdata?.data?.dictionaryLables?.no_pending_time_off_requests_hash;
  const timeoff_from = props?.attdata?.data?.dictionaryLables?.from;

  const timeoff_from_hash = props?.attdata?.data?.dictionaryLables?.from_hash;
  const timeoff_statussubheader =
    props?.attdata?.data?.dictionaryLables?.starting;
  const timeoff_statussubheader_hash =
    props?.attdata?.data?.dictionaryLables?.starting_hash;
  const pendingLabel = props?.attdata?.data?.dictionaryLables?.pending;
const onLabel =  props?.attdata?.data?.dictionaryLables?.on;
const onLabel_hash =  props?.attdata?.data?.dictionaryLables?.on_hash;

  useEffect(() => {
    if (
      data &&
      data.updatedTimeOffRequests &&
      data.updatedTimeOffRequests.length > 0
    ) {
      const timeOffReqst = data.updatedTimeOffRequests.filter(
        (item) => item.locationDescription === props.location
      );
      setTimeOffReqstData(timeOffReqst);
      // props.timeoffReqData(data);
    }
  }, [props.location, data]);

  const getDateFormat = (dateString) => {
    return moment(dateString).format("MM/DD/YYYY");
  };

  if (loading) return <Loader />;
  if (error)
    return (
      <div className="api-error">
        {" "}
        <APIError error={error} />
      </div>
    );

  return props.isMultiplePropertyEmployee ? (
    <section className={props.timeoffRequest}>
      <div className="col-12 row global_nopadding loa-request">
        <p className="timeoff_header" data-cslp={time_righttitle_hash}>
          {time_righttitle}
        </p>
        {timeOffReqstData && timeOffReqstData.length > 0 ? (
          timeOffReqstData.map((val, i) => {
            //const StartDate = getDateFormat(val.timeOffStartDate);
            const ApprovedDate = getDateFormat(val.approvedDate);
            const currentstatus = val.timeOffRequestStatus;
            const currentstatusToShow =
              currentstatus.toLowerCase() === "approved"
                ? time_approved
                : pendingLabel;
            let statusicon;
            if (currentstatusToShow === time_approved) {
              statusicon = <img src={availableimg} />;
            } else {
              statusicon = <img src={pendingimg} />;
            }

            return (
              <div key={i} className="timeoff_status">
                <div className="global_justifyspacebetween pt-4">
                  <span className="timeoff_statusheader">
                    {val.timeOffName}
                  </span>
                  <span
                    data-cslp={time_approved_hash}
                    className={`timeoff_statusindicator timeoff_pendingstatus-${currentstatusToShow === time_approved
                        ? "approved"
                        : "pending"
                      }`}
                  >
                    {statusicon}
                    {currentstatus === time_approved
                      ? time_approved
                      : currentstatusToShow}
                  </span>
                </div>
                {val.timeoffCombined.map((comObj) => {
                  let total = 0;
                  let units;
                  let StartDate;
                  let EndDate;
                  let unitsObj = [...new Set(comObj.map((req)=>req.units.toLowerCase()))];
                  let hasHalfday = (comObj.filter((item) => item.quantity <5));
                  if (unitsObj=="hours" && hasHalfday.length > 0) {

                    return (

                      <>

                        {comObj.map((obj) => {
                          units = obj.units;
                          total = obj.quantity
                          StartDate = getDateFormat(obj.timeOffDate);
                          return <>{<div
                            className="timeoff_statussubheader pb-2"
                            data-cslp={onLabel_hash}
                          >
                            {total} {units} {onLabel} {StartDate}
                          </div>}</>;
                        })}

                      </>

                    )
                  }
                  else {
                    return (
                      <>
                        {comObj.map((obj, index) => {
                          total += obj.quantity;
                          units = obj.units;
                          let lastIndex = comObj.length - 1;
                          if (index == 0) {
                            StartDate = getDateFormat(obj.timeOffDate);
                          }
                          if (index == lastIndex) {
                            EndDate = getDateFormat(obj.timeOffDate);
                          }
                          return <>{ }</>;
                        })}
                        {comObj.length > 1 ? <div
                          className="timeoff_statussubheader pb-2"
                          data-cslp={timeoff_from_hash}
                        >
                          {total} {units} {timeoff_from} {StartDate} - {EndDate}
                        </div> : <div
                          className="timeoff_statussubheader pb-2"
                          data-cslp={timeoff_statussubheader_hash}
                        >
                          {total} {units} {timeoff_statussubheader} {StartDate}
                        </div>}
                      </>
                    );
                  }
                })}
                {/*<div
                  className="timeoff_statussubheader pb-2"
                  data-cslp={timeoff_statussubheader_hash}
                >
                  {val.totalUnits} {val.unitOfTime} {timeoff_statussubheader}{" "}
                  {StartDate}
                    </div>*/}
                {currentstatus === time_approved ? (
                  <div
                    className="timeoff_statussent pb-4"
                    data-cslp={submittedOn_hash}
                  >
                    {submittedOn} {ApprovedDate}
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          })
        ) : (
          <div className="timeoff_nopending" data-cslp={time_nopending_hash}>
            {time_nopending}
          </div>
        )}
      </div>
    </section>
  ) : (
    <section className={props.timeoffRequest}>
      <div className="col-12 row global_nopadding loa-request">
        <p className="timeoff_header" data-cslp={time_righttitle_hash}>
          {time_righttitle}
        </p>
        {data.updatedTimeOffRequests?.length > 0 ? (
          data.updatedTimeOffRequests?.map((val, i) => {
            //const StartDate = getDateFormat(val.timeOffStartDate);
            const ApprovedDate = getDateFormat(val.approvedDate);
            const currentstatus = val.timeOffRequestStatus;
            const currentstatusToShow =
              currentstatus.toLowerCase() === "approved"
                ? time_approved
                : pendingLabel;
            let statusicon;
            if (currentstatusToShow === time_approved) {
              statusicon = <img src={availableimg} />;
            } else {
              statusicon = <img src={pendingimg} />;
            }

            return (
              <div key={i} className="timeoff_status">
                <div className="global_justifyspacebetween pt-4">
                  <span className="timeoff_statusheader">
                    {val.timeOffName}
                  </span>
                  <span
                    data-cslp={time_approved_hash}
                    className={`timeoff_statusindicator timeoff_pendingstatus-${currentstatusToShow === time_approved ? "approved" : "pending"
                      }`}
                  >
                    {statusicon}
                    {currentstatus === time_approved
                      ? time_approved
                      : currentstatusToShow}
                  </span>
                </div>
                {val.timeoffCombined.map((comObj) => {
                  let total = 0;
                  let units;
                  let StartDate;
                  let EndDate;
                  let unitsObj = [...new Set(comObj.map((req)=>req.units.toLowerCase()))];
                  let hasHalfday = (comObj.filter((item) => item.quantity < 5));
                  if (unitsObj=="hours" && hasHalfday.length > 0) {

                    return (

                      <>

                        {comObj.map((obj) => {
                          units = obj.units;
                          total = obj.quantity
                          StartDate = getDateFormat(obj.timeOffDate);
                          return <>{<div
                            className="timeoff_statussubheader pb-2"
                            data-cslp={onLabel_hash}
                          >
                            {total} {units} {onLabel} {StartDate}
                          </div>}</>;
                        })}

                      </>

                    )
                  }
                  else {
                    return (
                      <>

                        {comObj.map((obj, index) => {
                          total += obj.quantity;
                          units = obj.units;
                          let lastIndex = comObj.length - 1;
                          if (index == 0) {
                            StartDate = getDateFormat(obj.timeOffDate);
                          }
                          if (index == lastIndex) {
                            EndDate = getDateFormat(obj.timeOffDate);
                          }
                          return <>{ }</>;
                        })}
                        {comObj.length > 1 ? <div
                          className="timeoff_statussubheader pb-2"
                          data-cslp={timeoff_from_hash}
                        >
                          {total} {units} {timeoff_from} {StartDate}-{EndDate}
                        </div> : <div
                          className="timeoff_statussubheader pb-2"
                          data-cslp={onLabel_hash}
                        >
                          {total} {units} {onLabel} {StartDate}
                        </div>}
                      </>
                    );
                  }
                })}
                {/*<div
                  className="timeoff_statussubheader pb-2"
                  data-cslp={timeoff_statussubheader_hash}
                >
                  {val.totalUnits} {val.unitOfTime} {timeoff_statussubheader}{" "}
                  {StartDate}
                    </div>*/}
                {currentstatus === time_approved ? (
                  <div
                    className="timeoff_statussent pb-4"
                    data-cslp={submittedOn_hash}
                  >
                    {submittedOn} {ApprovedDate}
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          })
        ) : (
          <div className="timeoff_nopending" data-cslp={time_nopending_hash}>
            {time_nopending}
          </div>
        )}
      </div>
    </section>
  );
};

export default TimeoffRequest;
