import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { gql, useMutation, useQuery } from "@apollo/client";
import { apiMiddlewareClient } from "../../../features/api-wrapper/apiMiddlewareWrapper";
import InnerHtml from "../../dynamic/InnerHtml";
import { TextInput, Radio, Button, Calendar } from "@mgmresorts/mgm-ui";
// import moment from "moment";
import styles from "./CallingOutForm.module.css";
import ConfirmationPopup from "../../global/ConfirmPrompt";
import CustomSelectDropDown from "../../common/customSelectDropDown/customSelectDropdownType2";
import { GET_PROPERTIES } from "../../../features/api-wrapper/apiQueries";
import Loader from "../Loader";
import APIError from "../../../pages/ErrorHandling/APIError";

const SubmitCallingOutQuery = gql`
  mutation SubmitCallingOut($callingOutItem: CallingOutInput!) {
    submitCallingOut(callingOutItem: $callingOutItem) {
      status
      statusMessage
      errorCode
      errorMessage
    }
  }
`;

const RadioFD = ({
  label,
  attribute_id,
  options,
  opSelected,
  setOpSelected,
  isValid,
  errorMsg,
  isSubmitClicked = false,
  onChange,
}) => {
  return (
    <div className={styles.callingOutForm}>
      <p className={styles.formLabel}>{label}{attribute_id=='pto_fto'?<span className={styles.coStarField}>*</span>:<></>}</p>

      {options.map((item, index) => (
        <div
          key={index}
          className={styles.radioField}
          style={{ marginRight: "30px" }}
        >
          <Radio
            checked={opSelected === item.option_value}
            variant="small"
            name={item.option_name}
            value={item.option_value}
            onChange={(e) => {
              onChange();
              setOpSelected(e.target.value);
            }}
          />
          {/* todo: Cs update - I agree */}
          <label htmlFor={item.option_name}>{item.option_name}</label>
          {/* <label htmlFor={item.option_name}>{item.option_value}</label> */}
        </div>
      ))}
      {isSubmitClicked && !isValid && (
        <p className={styles.formErrorF}>{errorMsg}</p>
      )}
    </div>
  );
};

//todo: from cs

const CallingOutForm = (props) => {
  console.log("callout form props>>>>>>", props);
  const coData = props.data[0].data;

  const formNameValuePair = coData.nameValue;
  const formData = coData?.formContent;
  const nameValue = coData?.nameValue;
  const errorMsg = coData?.nameValue.map(
    (d) => d.name == "required_question_text" && d.value
  );

  const calendarWrapperRef = useRef();
  const calendarInputRef = useRef();
  let [calendarVisibility, setCalendarVisibility] = useState(false);

  const [lastDayWorkValue, setLastDayWorkValue] = useState({});

  // const [startDate, setStartDate] = useState(null);
  const [sTimeHr, setSTimeHr] = useState(null);
  const [sTimeMin, setSTimeMin] = useState(null);
  const [ampm,setAmpm] = useState(null);
  const [typeSelected, setType] = useState(null);
  const [ptoBSelected, setPtoB] = useState(null);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [iagree, setIagree] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  let shiftDateCS = false;
  let shiftTimeCS = false;
  let callOutTypeCS = false;
  let ftoCS = false;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fieldEdited, setFieldEdited] = useState(false);
  const [isContinue, setIsContinue] = useState(false);

  //dropdown Menu Toggle State
  const [toggleHrMenu, settoggleHrMenu] = useState(false);
  const [toggleMinMenu, settoggleMinMenu] = useState(false);

  const [isSDateValid, setisSDateValid] = useState(null);
  const [isSTimeValid, setisSTimeValid] = useState(null);
  const [isTypeValid, setisTypeValid] = useState(null);
  const [isPtobValid, setisPtobValid] = useState(null);
  const [isIagreeValid, setisIagreeValid] = useState(null);
  const [isAllValid, setisAllValid] = useState(null);
  const [moveNext, setMoveNext] = useState(false);
  const [locationSelected, setlocationSelected] = useState();
  const [locationCodeSelected, setlocationCodeSelected] = useState();
  //calender
  console.log(toggleHrMenu);
  console.log(toggleMinMenu);
  
 
  useEffect(() => {
    console.log("moveNext >>>>>>", moveNext);
  }, [moveNext]);
  useEffect(() => {
    if (isContinue) {
      // props.setModal(false);
      setIsModalOpen(false);
      setIsContinue(false);
      props.onClose(true);
      document.body.style.overflow = "visible";
      // window.open(props.data?.link, "_blank");
    }
  }, [isContinue]);

  

  useEffect(() => {
    lastDayWorkValue.dateStr ? setisSDateValid(true) : setisSDateValid(false);
  }, [lastDayWorkValue]);

  useEffect(() => {
    console.log("sTimeHr>>>>>", sTimeHr);
    (!sTimeHr === false && !sTimeMin === false && !ampm === false) ||
      (sTimeHr === null && sTimeMin === null && ampm === null)
      ? setisSTimeValid(true)
      : setisSTimeValid(false);
  }, [sTimeHr, sTimeMin,ampm]);

  useEffect(() => {
    typeSelected ? setisTypeValid(true) : setisTypeValid(false);
  }, [typeSelected]);

  useEffect(() => {
    ptoBSelected ? setisPtobValid(true) : setisPtobValid(false);
  }, [ptoBSelected]);

  useEffect(() => {
    iagree ? setisIagreeValid(true) : setisIagreeValid(false);
  }, [iagree]);

  useEffect(() => {
    // !startDate;
    !lastDayWorkValue.dateStr ? setisSDateValid(false) : setisSDateValid(true);

    !sTimeHr || !sTimeMin || !ampm ? setisSTimeValid(false) : setisSTimeValid(true);

    !typeSelected ? setisTypeValid(false) : setisTypeValid(true);

    !ptoBSelected ? setisPtobValid(false) : setisPtobValid(true);
  }, [isSubmitClicked]);

  useEffect(() => {
    //for_now
    
    let isdateValid = !shiftDateCS?true:isSDateValid?true:false;
    let isTimeValid = !shiftTimeCS?true:isSTimeValid?true:false;
    let isFtoValid =  !ftoCS?true:isPtobValid?true:false;
    let isCallOutValid = !callOutTypeCS?true:isTypeValid?true:false;

    console.log('isdateValid ???',isdateValid);
    console.log("isTimeValid ???",isTimeValid);
    console.log('isFtoValid ???',isFtoValid);
    console.log('isCallOutValid ???',isCallOutValid);

    let isValid = isdateValid && isTimeValid && isFtoValid && isCallOutValid;
    //let isValid = isSDateValid && isTypeValid
    setisAllValid(isValid);
  }, [isSDateValid, isSTimeValid, isTypeValid, isPtobValid,iagree]);
  
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        calendarWrapperRef.current &&
        !calendarWrapperRef.current.contains(event.target)
      ) {
        setCalendarVisibility(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const [
    submitFormMutation,
    { data: updateSubmitCallingOutInfo, loading, error },
  ] = useMutation(SubmitCallingOutQuery, { client: apiMiddlewareClient });
  console.log('loading  error of mutation >>>>>>', loading, error);
  
  const { loading2, error2, data } = useQuery(GET_PROPERTIES, {
    client: apiMiddlewareClient,
    fetchPolicy: "cache-first",
  });
  
  useEffect(() => {
    if (
      updateSubmitCallingOutInfo &&
      updateSubmitCallingOutInfo.submitCallingOut.status == 200
    ) {
      props.handleModalOpen(true);
      props.handleModalData(props.data[0]?.data);
      props.onClose(true);
    }
  }, [updateSubmitCallingOutInfo]);
  let propertyData = data?.employeeProperties.map((obj) => {
    console.log("obj>>>>>", obj);
    let propName = obj.name.replace(/^\s+|\s+$/gm, "");
    let propCode = obj.id;
    return { text: propName, value: propCode };
  });
  if (!propertyData) {
    propertyData = [];
  }
  // prop_aria prop_borgata prop_grand_detroit prop_national_harbor prop_vdara prop_yonkers prop_springfield
  //propertyData = [{text:'Aria',value:'prop_aria'},{text:'Borgata',value:'prop_borgata'},{text:'Grand Detriot',value:'prop_grand_detroit'},{text:'National Harbor',value:'prop_national_harbor'},{text:'Vdara',value:'prop_vdara'},{text:'Yonkers',value:'prop_yonkers'},{text:'Springfield',value:'prop_springfield'}]
  useEffect(() => {
    if (propertyData && propertyData[0] && typeof locationCodeSelected == 'undefined') {
      setlocationSelected(propertyData[0].value)
      setlocationCodeSelected(propertyData[0].value);
    }

    console.log("locationCodeSelected>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???>>>>>>>>", locationCodeSelected);
  }, [propertyData]);
  console.log("loading multiple property data >>>>>>", loading2, error2, data);
  console.log("formData >>>>", formData);

  const [isPropertySelected, setIsPropertySelected] = useState(false);
  let propertyCSData = {};
  formData.map((obj) => {
    if (obj.form_attribute) {
      obj.form_attribute.reference.map((obj2) => {
        if (obj2.attribute_id == "property") {
          propertyCSData = obj2;
        }
      });
    }
  });
  console.log("coData >>>>", coData);
  console.log("propertyCSData >>>>", propertyCSData);
  console.log("formNameValuePair >>>>", formNameValuePair);
  let cancelLabel;
  let nextLabel;
  formNameValuePair.map((obj) => {
   
    if (obj.name == "cancel_button_label") {
      cancelLabel = obj.value;
    }
    if (obj.name == "next_button_label") {
      nextLabel = obj.value;
    }
  });

  
  let isMultipProp = useSelector((state) => {
    console.log("state>>>>", state);
    return state?.gqlData?.profile?.isMultiplePropertyEmployee;
  });
  

  console.log("isMultipProp>>>>>", isMultipProp);
  console.log("propertyData>>>>>", propertyData);
  console.log("locationSelected>>>>>", locationSelected);
  //console.log("locationCodeSelected>>>>", locationCodeSelected);
 
  

  // Calender
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  const openCalendar = () => {
    setCalendarVisibility(!calendarVisibility);
  };
  const handleKeyDown = (event) => {
    event.preventDefault();
  };
  const dateChangeHandler = (value) => {
    const [year, month, day] = value.split("-");
    props.handleFieldEdited(true);
    setFieldEdited(true);

    let date = new Date(year, month - 1, day);

    // date.setDate(date.getDate());
    const formattedYear = date.getFullYear();
    const formattedMonth = String(date.getMonth() + 1).padStart(2, "0");
    const formattedDay = String(date.getDate()).padStart(2, "0");

    const formattedValue = `${formattedMonth}/${formattedDay}/${formattedYear}`;
    console.log(lastDayWorkValue);

    setLastDayWorkValue({
      date,
      dateStr: formattedValue,
      dateFormatted: getFormattedDate(date),
    });
    setCalendarVisibility(false);
  };

 

  const getFormattedDate = (date) => {
    const days = globalProperties?.full_day?.split("|");
    console.log(days);
    const day = days.length !== 0 ? days[date.getUTCDay() - 1] : "";
    const month = globalProperties?.full_month[date.getUTCMonth()];
    return `${day}, ${month} ${date.getUTCDate()}, ${date.getUTCFullYear()}`;
  };


  const sTimeLabel = formData[1]?.form_attribute_group?.reference[0].title;
  const calloutoptions = formData[2].form_attribute.reference[0].option_values;
  const houroptions =
    formData[1].form_attribute_group?.reference[0].reference[0].option_values;
  const minutesoptions =
    formData[1].form_attribute_group?.reference[0].reference[1].option_values;

  console.log("calloutoptions", calloutoptions);
  console.log("houroptions", houroptions, minutesoptions);

  const callout = calloutoptions.map((val) => {
    return { text: val.option_name, value: val.option_value };
  });
  console.log("callout che options map zale", callout);
  const getCalloutTypeOptions = (field)=>{
    let newOption= []
    field.option_values.map((obj)=>{
      newOption.push({ text: obj.option_name, value: obj.option_value }) ;
      
    })
    return newOption;
  }
  const hour = houroptions.map((val) => {
    return { text: val.option_name, value: val.option_value };
  });
  console.log("hour che options map zale", hour);
  const minutes = minutesoptions.map((val) => {
    return { text: val.option_name, value: val.option_value };
  });
  const getAmpmList = (field)=>{
    let newOption= []
    field.option_values.map((obj)=>{
      newOption.push({ text: obj.option_name, value: obj.option_value }) ;
      
    })
    return newOption;
  }
  console.log("hour che options map zale", minutes);

  const handleClose = () => {
    if (fieldEdited) {
      // console.log("entered modal window");
      setIsModalOpen(true);
      document.body.style.overflow = "visible";
    } else {
      // setZindex(false);
      props.onClose(true);
      // props.setModal(false);
      document.body.style.overflow = "visible";
    }
  };
  const filterFormFields = (field) => {
    let returnVal;
    
    if (!field.applicable_properties) {
      returnVal = true;
    }
    if (
      field.applicable_properties &&
      field.applicable_properties.length == 0
    ) {
      returnVal = true;
    }
    if (field.applicable_properties && field.applicable_properties.length > 0) {
      field.applicable_properties.map((obj)=>{
        let incomingPropertyId = obj.property_logo.property_id;
        if (incomingPropertyId == locationCodeSelected) {
          returnVal = true;
          
        }
      })
      
    }
    if (
      field.non_applicable_properties &&
      field.non_applicable_properties.length > 0
    ) {
      field.non_applicable_properties.map((nobj)=>{
        let incomingPropertyId =
        nobj.property_logo.property_id;
        if (incomingPropertyId == locationCodeSelected) {
          returnVal = false;
          
         
        }
      });
      
    }
    if (!field.non_applicable_properties && typeof returnVal == 'undefined') {
      returnVal = true;
    }
    if (
      field.non_applicable_properties &&
      field.non_applicable_properties.length > 0 &&
      typeof returnVal == 'undefined'
    ) {
      returnVal = true;
    }
    return returnVal;
  };
  const formField = (field) => {
    const includeField = filterFormFields(field);
    if (!includeField) {
      return;
    }
    console.log("field", field);
    switch (field.attribute_type) {
      case "date":
        shiftDateCS = true;
        
        return (
          <div>
            <div
              ref={calendarWrapperRef}
              className={`${styles.field} ${styles.calendar} ${styles.calendarFieldWrapper} col-12 row`}
            >
              <p
                className={`${styles.formLabel} ${styles.calendarFieldContentAlign}`}
              >
                {field.label}
                <span className={styles.coStarField}>*</span>
              </p>

              {/* <label style={{ marginBottom: "8px" }}> {field.label}</label> */}
              <div
                className={styles.calendarFieldContentAlign}
                onClick={() => {
                  openCalendar();
                }}
              >
                <TextInput
                  ref={calendarInputRef}
                  id="when"
                  className={styles.myCalendar}
                  // label={formContent?.when?.label}
                  placeholder={field.place_holder_text}
                  icon="calendar-basic"
                  onClick={(e) => openCalendar(e)}
                  onBeforeInput={handleKeyDown}
                  value={lastDayWorkValue.dateStr} //startDate
                  onKeyDown={handleKeyDown}
                  onMouseDown={handleKeyDown}
                  autoComplete="off"
                ></TextInput>
              </div>
              <div
                className={`${styles.calendarBox}  ${calendarVisibility ? "" : styles.hide
                  }`}
                style={{ top: "95%" }}
              >
                <Calendar onChange={(e) => dateChangeHandler(e)}></Calendar>
              </div>
            </div>

            {isSubmitClicked && !isSDateValid && (
              <p className={styles.formErrorF}>{errorMsg}</p>
            )}
          </div>
        );

      case "textarea":
        return (
          <>
            <p className={styles.formLabel}>{field.label}</p>
            <textarea
              // id={content.attribute_id}
              rows="5"
              className={styles.textareaField}
              placeholder={field.place_holder_text}
              value={additionalInfo}
              onChange={(e) => {
                setFieldEdited(true); //todo: check for blank
                setAdditionalInfo(e.target.value);
              }}
            ></textarea>
          </>
        );

      case "radio":
        
        if (field.attribute_id == "pto_fto") {
          ftoCS = true;
          return (
            // <div style={{ marginTop: "60px" }}>
            <RadioFD
              label={field.label}
              attribute_id={field.attribute_id}
              options={field.option_values}
              opSelected={ptoBSelected}
              setOpSelected={setPtoB}
              isValid={isPtobValid}
              isSubmitClicked={isSubmitClicked}
              errorMsg={errorMsg}
              onChange={() => {
                setFieldEdited(true);
                props.handleFieldEdited(true);
              }}
            />
            // </div>
          );
        } else if (field.attribute_id == "acknowledgement") {
          return (
            <div>
              <p>
                {field.label}
                <span className={styles.coStarField}>*</span>
              </p>
              <div className={styles.coAckText}>
                <InnerHtml description={field.attribute_description} />
              </div>
              <RadioFD
                options={field.option_values}
                attribute_id={field.attribute_id}
                opSelected={iagree}
                setOpSelected={setIagree}
                isValid={isIagreeValid}
                isSubmitClicked={isSubmitClicked}
                errorMsg={errorMsg}
                onChange={() => {
                  setFieldEdited(true);
                  props.handleFieldEdited(true);
                }}
              />
            </div>
          );
        }
        break;
      case "dropdown":
        {
          if (field.attribute_id == "callout_type") {
            callOutTypeCS = true;
            return (
              <>
                <p>
                  {field.label}
                  <span className={styles.coStarField}>*</span>
                </p>
                <div className={styles.customDropdownN}>
                  <CustomSelectDropDown
                    id="callout_type"
                    label={field.place_holder_text}
                    // text="date"
                    optionsList={getCalloutTypeOptions(field)}
                    isType2={true}
                    onChange={(index, obj) => {
                      setFieldEdited(true); //todo: check for blank
                      props.handleFieldEdited(true);

                      // const inedx = callout.findIndex(
                      //   (item) => item.value === obj.value
                      // );
                      // setType(callout[inedx]);

                      setType(obj.value);
                      settoggleMinMenu(true);
                    }}
                  />
                </div>
                {isSubmitClicked && !isTypeValid && (
                  <p className={styles.formErrorF} style={{ marginTop: "7px" }}>
                    {errorMsg}
                  </p>
                )}
                
              </>
            );
          } else if (field.attribute_id == "ampm") {
            return (
              <div className={`${styles.ddOuter} ${styles.customDropdownN} ${styles.ampm}`}>
                <CustomSelectDropDown
                  id="ampm"
                  label={field.place_holder_text}
                  text="ampm"
                  optionsList={getAmpmList(field)}
                  isType2={true}
                  onChange={(index, obj) => {
                    setFieldEdited(true); //todo: check for blank
                    props.handleFieldEdited(true);
  
                    
                    setAmpm(obj);
                    settoggleMinMenu(false);
                  }}
                />
                
              </div>
            );
          }
          else if (field.attribute_id == "hour") {
            //todo: this should be dropdown type
            // console.log(hourDD);
            shiftTimeCS = true;
            return (
              <div>
                <div className={`${styles.ddOuter1} ${styles.customDropdownN}`}>
                  <p>
                    {field.label} <span className={styles.coStarField}>*</span>
                  </p>
                  <CustomSelectDropDown
                    id="hour"
                    label={field.place_holder_text}
                    text="date"
                    optionsList={hour}
                    isType2={true}
                    onChange={(index, obj) => {
                      setFieldEdited(true); //todo: check for blank
                      props.handleFieldEdited(true);
  
                      // const inedx = hourDD.findIndex(
                      //   (item) => item.value === obj.value
                      // );
                      // setSTimeHr(hourDD[inedx]);
                      setSTimeHr(obj);
                      settoggleHrMenu(false);
                    }}
                  />
                  
                </div>
              </div>
            );
          } else if (field.attribute_id == "minute") {
            return (
              <div className={`${styles.ddOuter} ${styles.customDropdownN}`}>
                <CustomSelectDropDown
                  id="minute"
                  label={field.place_holder_text}
                  text="date"
                  optionsList={minutes}
                  isType2={true}
                  onChange={(index, obj) => {
                    setFieldEdited(true); //todo: check for blank
                    props.handleFieldEdited(true);
  
                    // const inedx = minDD.findIndex(
                    //   (item) => item.value === obj.value
                    // );
                    // setSTimeMin(minDD[inedx]);
                    setSTimeMin(obj);
                    settoggleMinMenu(false);
                  }}
                />
                
              </div>
            );
          }
        }
        break;

      default:
        return;
    }
  };
  if (error) return <APIError error={error} />;
  if (error2) return <APIError error={error2} />;
  console.log('loading2 >>>>>>>>>>>>>>>>>>>>',loading2);
  return (<>{ !data ?(<Loader></Loader>): (
    (<>
      {isMultipProp && !moveNext ? (
        <>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: propertyCSData.attribute_description,
            }}
          ></div>
          <p className={styles.labelProp}>
            {propertyCSData.label}
            <span className={styles.coStarField}>*</span>
          </p>
          <div
            className={`${styles.ddPropertyOuter} ${styles.customDropdownN}`}
            style={{ marginBottom: "70px" }}
          >
            <CustomSelectDropDown
              id="property"
              label={propertyCSData.place_holder_text}
              text="property"
              optionsList={propertyData}
              isType3={true}
              onChange={(index, obj) => {
                console.log("selected object >>>>", obj);
                setFieldEdited(true);
                setlocationSelected(obj.text);
                setlocationCodeSelected(obj.value);
                setIsPropertySelected(true);
              }}
            />
          </div>
          <div
            className={styles.formFieldSpacing}
            style={{ display: "flex", gap: "24px", justifyContent: "end" }}
          >
            <Button
              label={cancelLabel}
              className={`${styles.coButton} ${styles.cancelBtn}`}
              onClick={() => handleClose()}
              variant="primary"
            />
            <Button
              label={nextLabel}
              disabled={!isPropertySelected}
              variant="primary"
              onClick={() => {
                setMoveNext(true);
              }}
              className={`${styles.coButton} ${styles.submitBtn}`}
            />
          </div>
          {isModalOpen && (
            <ConfirmationPopup
              isModalOpen={isModalOpen}
              onContinue={setIsContinue}
              fieldEdited={fieldEdited}
              onClose={() => {
                setIsModalOpen(false);
                // setZindex(false);
              }}
            />
          )}
        </>
      ) : <>{data?.employeeProperties && (
        <>
          
          <div className={styles.callingOutForm}>
           
            <div
              className={`${styles.formFieldSpacing} ${styles.coText}`}
              style={{ marginBottom: "10px" }}
            >
              <InnerHtml description={coData.formDescription} />
            </div>

            {formData[0]?.form_attribute?.reference?.map((d, i) => {
              return (
                <div key={"field0" + i} className={styles.formFieldSpacing}>
                  {formField(d)}
                </div>
              );
            })}

            <div className={styles.formFieldSpacing}>
              <div className={styles.shiftDropdown}>
                {formData[1]?.form_attribute_group?.reference[0].reference.map(
                  (d, i) => {
                    return (
                      <div
                        key={"field1" + i}
                        className={styles.shiftDropdownDiv}
                      >
                        {formField({
                          ...d,
                          label: sTimeLabel,
                        })}
                      </div>
                    );
                  }
                )}
              </div>

              {isSubmitClicked && !isSTimeValid && (
                <p className={styles.formErrorF}>{errorMsg}</p>
              )}
            </div>

            {formData[2].form_attribute?.reference.map((d, i) => {
              return (
                <div key={"field2" + i} className={styles.formFieldSpacing}>
                  {formField(d)}
                </div>
              );
            })}

            <div
              className={styles.formFieldSpacing}
              style={{ display: "flex", gap: "24px", justifyContent: "end" }}
            >
              <Button
                label={nameValue.map(
                  (d) => d.name == "cancel_button_label" && d.value
                )}
                className={`${styles.coButton} ${styles.cancelBtn}`}
                onClick={() => handleClose()}
                variant="primary"
              />

              <Button
                label={nameValue.map(
                  (d) => d.name == "submit_button_label" && d.value
                )}
                disabled={!iagree || !isAllValid}
                variant="primary"
                style={{ marginBottom: "15px" }}
                className={`${styles.coButton} ${styles.submitBtn}`}
                onClick={() => {
                  setIsSubmitClicked(true);
                  console.log('isAllValid >>>>>>>>>>>>>>>>',isAllValid);
                  if (isAllValid) {
                    submitFormMutation({
                      variables: {
                        callingOutItem: {
                          shiftStartDate: lastDayWorkValue.dateStr?lastDayWorkValue.dateStr:'',
                          shiftStartTime: sTimeHr?sTimeHr.value + ":" + sTimeMin.value+ " "+ampm.value:'',
                          callingOutType: typeSelected?typeSelected:'',
                          isPTOOrFTO: ptoBSelected?ptoBSelected:'',
                          additionalInfo: additionalInfo?additionalInfo:'',
                          property_id: locationCodeSelected,
                          acknowledgement: true,
                        },
                      },
                    });
                  } else {
                    //todo: confirmation modal on modal close cross btn
                  }
                }}
              />
             
            </div>

            {isModalOpen && (
              <ConfirmationPopup
                isModalOpen={isModalOpen}
                onContinue={setIsContinue}
                fieldEdited={fieldEdited}
                onClose={() => {
                  setIsModalOpen(false);
                  // setZindex(false);
                }}
              />
            )}
          </div>
        </>
      )}</>}
    </>)
  )}</>);
};

export default CallingOutForm;
