import ReactDOM from "react-dom";
import { Button,  } from "@mgmresorts/mgm-ui";
import styles from "./Modal.module.css";
import closeIcon from "../../../assets/images/symbol-x-black.svg";

const Backdrop = (props) => {
  return <div className={styles.backdrop} onClick={props.onClose} />;
};

const ModalOverlay = (props) => {
  const showCloseBtn =
    props.showCloseBtn != undefined ? props.showCloseBtn : true;

  return (
    <div className={styles.modal}>
      <div>
        <div className={styles.modalHeader}>
          <h3>{props.heading}</h3>
          {showCloseBtn && (
            <button className={styles.closeButton} onClick={props.onClose}>
              <img src={closeIcon} />
            </button>
          )}
        </div>
        <div className={styles.content}>{props.children}</div>
      </div>
    </div>
  );
};

const portalElement = document.getElementById("overlays");

const Modal = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          onClose={props.onClose}
          heading={props.heading}
          showCloseBtn={props.showCloseBtn}
        >
          {props.children}
          {props.finishButton && <div className={styles.buttonHolder}><Button onClick={props.onClose} variant="primary">{props.finishLabel}</Button></div>}
        </ModalOverlay>,
        portalElement
      )}
    </>
  );
};

export default Modal;
