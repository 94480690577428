import styles from "./CTAQuickAction.module.css";
import { useNavigate } from "react-router-dom";
//import ContentAuthorNote from "../../pages/ErrorHandling/ContentAuthorNote";
import { useEffect, useState } from "react";
import ConfirmationPopup from "../global/ConfirmPrompt";
import { Icon } from "@mgmresorts/mgm-ui";
import { useSelector } from "react-redux";

const CTAQuickAction = (props) => {
  //console.log("CTAQuickAction >");
  let size = props.size ? props.size : "small";

  if (props.data === null) return;
  if (props.data.length === 0) return;

  return (
    <>
      {props.data && (
        <>
          {Array.isArray(props.data) &&
            props.data.length !== 0 &&
            props.data.map((item, index) => (
              <CTAQuickActionSingle
                data={item}
                key={index}
                size={size}
                {...props}
              />
            ))}
          {!Array.isArray(props.data) && (
            <CTAQuickActionSingle size={size} {...props} />
          )}
        </>
      )}
    </>
  );
};

export default CTAQuickAction;

const CTAQuickActionSingle = (props) => {
  //console.log("props>", props);
  const globalNotification = useSelector((state) => state.globalNotification);
  const { whiteListedDomain } = globalNotification || [];
  let url = props.data?.url;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContinue, setIsContinue] = useState(false);

  //let linkVariants = ["text_link", "help_topics"];
  const navigate = useNavigate();

  const clickHandler = (e, opensIn) => {
    e.preventDefault();
    if (opensIn === "new_window") {
      whiteListedDomain.some((v) => url.includes(v))
        ? window.open(url, "_blank")
        : setIsModalOpen(true);
    } else if (opensIn === "same_window") {
      navigate(url);
    } else if (opensIn === "modal") {
      props.openModal(props.args);
    }
  };

  useEffect(() => {
    if (isContinue) {
      setIsModalOpen(false);
      setIsContinue(false);
      window.open(url, "_blank");
    }
  }, [isContinue]);

  return (
    <>
      {props.data?.label !== "" && (
        <>
          <div
            onClick={(e) => clickHandler(e, props.data?.openIn)}
            className={`${styles.quickAction} ${props.style} ${
              styles[props.panelSize]
            }`}
          >
            {props.data?.leadingIcon && (
              <div className={styles.leadingIcon}>
                <Icon
                  name={props.data?.leadingIcon}
                  variant="outlined"
                  size="large"
                  role="img"
                />
              </div>
            )}
             
            <div>
              <h4>{props.data?.label}</h4>
              {props.data?.description && <p>{props.data?.description}</p>}
            </div>
            {props.data?.trailingIcon && (
              <div className={styles.trailingIcon}>
                <Icon
                  name={props.data?.trailingIcon}
                  variant="filled"
                  size="large"
                  role="img"
                  color="#BAA173"
                />
              </div>
            )}
          </div>

          {isModalOpen && (
            <ConfirmationPopup
              isModalOpen={isModalOpen}
              onContinue={setIsContinue}
              onClose={() => {
                setIsModalOpen(false);
              }}
            />
          )}
        </>
      )}
    </>
  );
};
