import style from "./SiteMaintananceModal.module.css"
import { useState,useEffect } from "react";
import useFetchData from "../../../hooks/useFetchData";
import {setLocalStoreValue} from "./../../../utils/localStore"
const SiteMaintananceModal = ()=>{
    const [hidePopup,setHidePopup] = useState(false);
    const [siteMaintanance,setSiteMaintanance] = useState();
    const hodePopup = ()=>{
        setHidePopup(true);
        setLocalStoreValue('maintenance','true');
    }
    const { data: csGlobalData } = useFetchData("", "globals");
    console.log('global data >>>>',csGlobalData);
    const systemErrors = csGlobalData?csGlobalData.data?.systemErrors:[];
    useEffect(()=>{
        if(systemErrors)
        {
            let filterData = systemErrors.filter((obj)=>{
                if(obj.type == "website_maintenance")
                {
                    return obj;
                }
            })
            if(filterData && filterData.length>0)
            {
                setSiteMaintanance(filterData[0]);
            }
            
        }
        
    },[systemErrors])
    
    useEffect(()=>{
        console.log('siteMaintanance>>>',siteMaintanance);
    },[siteMaintanance])

    return(<div className={`${style.overlay} ${hidePopup?'hide':''}`}>
        {!siteMaintanance?<></>
         :<div className={style.innerHolder}><div className={style.coreHolder}>
        <div className={style.cross} onClick={hodePopup}></div>
        <div className={style.errorIcon} style={{background: `url("${siteMaintanance.image?.url}") no-repeat`}}></div>
        <div className={style.head}>{siteMaintanance.heading}</div>
        <div className={style.body} dangerouslySetInnerHTML={{
                  __html: siteMaintanance.description
                }}></div>
        <button className={style.contBtn} onClick={hodePopup}>{siteMaintanance.continue_button_text
}</button>
        </div></div>}
    </div>)
}
export default SiteMaintananceModal;