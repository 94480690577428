import { gql } from "@apollo/client";

export const GET_PAY_DETAILS = gql`
  query GetPayslip {
    payslip {
      takeHomePay
      payslipStartDate
      currencyCode
      payslipEndDate
      grossAmount
      location
      payGroupReference
      payslipPaymentDate
      timesheetHours
      earnings {
        amount
        category
        name
      }
      deductions {
        amount
        category
        name
      }
    }
  }
`;
export const SET_HIDE_PAY = gql`
  mutation UpdateHidePayStatus($status: Boolean!) {
    updateHidePayStatus(status: $status)
  }
`;
export const GET_HR_DETAILS = gql`
  query HrItCases {
    hrCases {
      noOfOpenCases
      noOfTasks
    }
  }
`;
export const GET_NOTIFICATION = gql`
  query NotificationQuery($fromDate: String!, $toDate: String!) {
    notifications(fromDate: $fromDate, toDate: $toDate) {
      message
      timeReceived
    }
  }
`;

export const GET_IT_DETAILS = gql`
  query HrItCases {
    itCases {
      noOfOpenCases
      noOfApprovals
    }
  }
`;

export const GET_ATTENDANCE_DETAILS = gql`
  query GetAttendanceDetails {
    attendanceHistory {
      date
      location
      quantity
      unit
    }
    attendanceLeaveBalance {
      allocationEndDate
      allocationStartDate
      locationDescription
      multiplePropertyIndicator
      positionDescription
      positionID
      remainingDays
      companyCode
      companyName
    }
  }
`;
export const GET_EligibleLOAPlans = gql`
query EligibleLOAPlans {
  eligibleLOAPlans {
    planDisplayName
    subPlanDisplayName
    planReferenceId
    planReasons {
      reasonName
      reasonRefId
    }
  }
  positionReferences {
    positionId
    positionName
    primary
  }
}
`;
export const GET_PROFILE_DETAILS = gql`
  query getProfileDetails {
    personalInformation {
      fullName
      preferredName
      preferredFirstName
      preferredLastName
      personalEmail
      secondaryEmail
      homePhone
      addressLine1
      addressLine2
      postalCode
      workEmailAddresses
      city
      state
      preferredLanguage
      licencePlate {
        id
        state
        plateNo
      }
    }
    profile {
      employeeId
      workerId
      fullName
      preferredName
      userLocation
      legalFirstName
      legalLastName
      primaryLocation
      primaryLocationLogo
      businessTitle
      preferredLanguage
      hidePayslip
      favourites {
        url
        title
        subTitle
        description
      }
      payRateGroup
      isFTOEligible
      isMultiplePropertyEmployee
    }
    supervisorDetails {
      employeeId
      workerId
      name
      workEmail
      workPhoneNumber
      location
      businessTitle
    }
  }
`;
export const GET_TIMEOFFBALANCE_DETAILS = gql`
  query GetTimeOffBalanceDetails {
    timeOffLeaveBalance {
      positionID
      positionDescription
      timeOffPlanName
      timeOffBalance
      balancePeriodStartDate
      balancePeriodEndDate
      timeOffAccured
      locationId
      locationDescription
      multiplePropertyEmployee
      companyCode
      companyName
    }
    timesheetMultipleLocations {
      Company
      code
    }
  }
`;

export const GET_TIMEOFFREQUEST_DETAILS = gql`
  query GetTimeOffRequestDetails {
    timeOffRequests {
      timeOffRequestId
      locationId
      locationDescription
      timeOffRequestStatus
      totalUnits
      unitOfTime
      timeOffStartDate
      approvedDate
      timeOffName
    }
  }
`;
export const GET_TIMEOFFREQUEST_DETAILS_UPDATED = gql`
  query GetTimeOffRequestDetailsUpdated {
    updatedTimeOffRequests {
      approvedDate
      locationDescription
      locationId
      timeOffName
      timeoffCombined {
        quantity
        timeOffDate
        units
      }
      timeOffRequestId
      timeOffRequestStatus
      timeoffDates {
        quantity
        timeOffDate
        units
      }
    }
  }
`;

export const GET_PAST_SIX_MONTH_FTO = gql`
  query GetSixMonthFTO {
    PastSixMonthFTOCount {
      units
      typeOfUnits
    }
  }
`;
export const GET_EMPLOYEE_DETAILS = gql`
  query employeeDetails($employeeId: Int!) {
    employeeDetails(employeeId: $employeeId) {
      data {
        employeeId
        name
        businessTitle
        location
        companyName
      }
      errorMessage
      status
    }
  }
`;

export const GET_FEATURED_PROPERTY_NEWS = gql`
  query Query {
    featuredPropertyNews {
      images {
        imageHeight
        imageURL
        imageWidth
      }
      imagesExist
      links
      linksExist
      newsBody
      newsContentType
      newsId
      newsPublishedDate
      newsSummary
      newsTitle
      videoExists
      videos {
        videoPreviewImageURL
        videoEmbedHTML
        videoWidth
        videoHeight
        videoUrl
        videoMP4Url
      }
      leoLink
    }
  }
`;

export const GET_PROPERTY_CALENDER = gql`
  query FilteredPropertyCalendar {
    filteredPropertyCalendar {
      leolink
      body
    }
  }
`;

export const GET_CLOCKEDHOURS = gql`
  query clockedHours {
    clockedHours {
      currentSystemDate
      employeeTimezone
      error
      isClockedIn
      lastPunchTime
      lastPunchType
      timeframeEndDate
      timeframeStartDate
    }
  }
`;
export const GET_WEEKLYUPDATES = gql`
  query weeklyUpdates {
    weeklyUpdates {
      images {
        imageURL
        imageWidth
        imageHeight
      }
      leoLink
      title
    }
  }
`;
export const GET_RELATED_NEWS = gql`
  query Query {
    relatedNews {
      newsId
      newsPublishedDate
      newsTitle
      newsSummary
      newsContentType
      newsBody
      linksExist
      links
      imagesExist
      images {
        imageURL
        imageWidth
        imageHeight
      }
      videoExists
      leoLink
      videos {
        videoPreviewImageURL
        videoEmbedHTML
        videoWidth
        videoHeight
        videoUrl
        videoMP4Url
      }
    }
  }
`;

// check if this query is duplicate
export const GET_MGM_NEWS = gql`
  query Query {
    mgmNews {
      newsId
      newsPublishedDate
      newsTitle
      newsSummary
      newsContentType
      newsBody
      linksExist
      links
      imagesExist
      videoExists
      videos {
        videoPreviewImageURL
        videoEmbedHTML
        videoWidth
        videoHeight
        videoUrl
        videoMP4Url
      }
      images {
        imageURL
        imageWidth
        imageHeight
      }
    }
  }
`;
export const updateLOADetails = gql`
  mutation RequestleaveOfAbsence($requestLoaInput: RequestLOAInput!) {
    requestleaveOfAbsence(requestLOAInput: $requestLoaInput) {
      statusCode
      statusMessage
      errorCode
      errorMessage
    }
  }
`;

export const ADD_FAVOURITES = gql`
  mutation Mutation(
    $pageUrl: String!
    $title: String
    $subTitle: String
    $description: String
  ) {
    addFavourite(
      pageUrl: $pageUrl
      title: $title
      subTitle: $subTitle
      description: $description
    ) {
      status
      data {
        title
        subTitle
        url
        description
      }
      errorMessage
    }
  }
`;

export const REMOVE_FAVOURITES = gql`
  mutation RemoveFavourite($pageUrl: String!) {
    removeFavourite(pageUrl: $pageUrl) {
      errorMessage
      status
    }
  }
`;

export const GET_PROPERTIES = gql`
  query EmployeeProperties {
    employeeProperties {
      id
      name
    }
  }
`;

export const GET_TIMESHEET_MULTIPLE_LOCATIONS = gql`
  query TimesheetMultipleLocations {
    timesheetMultipleLocations {
      Company
      code
    }
  }
`;

export const GET_PAYPERIODS_DROPDOWN = gql`
  query PayperiodsDropDown {
    payperiodsDropDown {
      companyCode
      companyName
      positionID
      payPeriods {
        payPeriodStartDate
        payPeriodEndDate
      }
    }
  }
`;

export const GET_TIMESHEET_DETAILS = gql`
  query Profile($startDate: String, $endDate: String, $locationId: String) {
    timeSheet(
      startDate: $startDate
      endDate: $endDate
      locationID: $locationId
    ) {
      payPeriodStartDate
      payPeriodEndDate
      isApproved
      payCodes {
        payCodeName
        payCodeDateTime
        payCodeAmountInSeconds
      }
      timesheetDateWise {
        timeSheetRow {
          timeIn
          timeOut
          isTimeInEdited
          isTimeOutEdited
          missedInPunch
          missedOutPunch
          location
          transferIndicator
          exception
          tipCompliance
          tipsBreakdown {
            TipName
            Amount
          }
        }
        timesheetDate
        hours
        isDateApproved
      }
    }
  }
`;

export const SET_PREFERRED_LANGUAGE = gql`
  mutation UpdateLanguagePreference($language: String!) {
    updateLanguagePreference(language: $language)
  }
`;

export const MY_TASKS_NOTIFICATION = gql`
  query MyTasks {
    myTasks {
      taskMessage
      taskDescription
      timeReceived
      dueOn
    }
  }
`;

export const GET_MY_APPS = gql`
  query myApps($locale: String) {
    myApps(locale: $locale) {
      icon
      displayName
      description
      link
      source
      subHeading
    }
  }
`;

export const GET_USER_ENTERED_CONTEST = gql`
query Query {
  hasUserEnteredContest {
    userContestParticipatedStatus
  }
}
`;

export const SET_USER_ENTERED_CONTEST = gql`
mutation ContestLaunchEvent($systemDate: String) {
  contestLaunchEvent(systemDate: $systemDate) {
    status
    userContestParticipatedStatus
    systemDate
    employeeId
    employeeName
    employeeBusinessTitle
    employeePrimaryLocation
  }
}
`;

export const GET_MY_SCHEDULE = gql`
  query MySchedule {
    mySchedule {
      dayWiseSchedule {
        startTime
        endTime
        overnight
        startDate
        site
        role
        location
        notes
        leaves
      }
      onWaitlistDates
      dayTagDates
      isMessageAvailable
      isAckMessageAvailable
    }
  }
`;

export const GET_REQUEST_TIMEOFF = gql`
  query Query {
    eligibleTimeOffPlans {
      positionID
      timeOffPlans {
        planDisplayName
        planReferenceCode
        planUnitOfTime
      }
    }
    positionReferences {
      positionId
      positionName
      primary
    }
  }
`;

export const UPDATE_REQUEST_TIMEOFF = gql`
  mutation Mutation($requestTimeOffInput: RequestTimeOffInput!) {
    requestTimeOff(requestTimeOffInput: $requestTimeOffInput) {
      statusCode
      statusMessage
      errorCode
      errorMessage
    }
  }
`;
